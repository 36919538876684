import HtmlFragment from "./html-fragment";

export default function FourOFour(props) {
  return (
    <div className="py-48 md:py-112">
      <div className="text-display h3 container text-dark">
        <HtmlFragment>{props?.body}</HtmlFragment>
      </div>
    </div>
  );
}
