import clsx from "classnames";

import get from "lodash.get";
import map from "lodash.map";

import { Cta, HtmlFragment } from "./*";
import isEmpty from "lodash.isempty";

const BASE_CLASS = "note";

function Note(props) {
  let { note } = props;

  return !isEmpty(props) ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="pb-24">
        {Array.isArray(note) ? (
          <div className="grid gap-40 ipad:gap-80">
            {map(note, (block, i) => {
              return <Block key={i} {...block} />;
            })}
          </div>
        ) : (
          <div className="grid gap-40 ipad:gap-80">
            {note?.description ? <Block {...note} /> : null}
          </div>
        )}
      </div>
    </div>
  ) : null;
}

function Block(props) {
  const { ctaVersion, cta, title, description, className } = props;
  const mainNote =
    "relative border-l-8 border-grey-600 bg-grey-200 bg-[url('/images/note-vector.webp')] bg-cover bg-no-repeat py-20 px-36 md:py-56";

  return ctaVersion ? (
    <div
      className={clsx(mainNote, className)}
      key={`${title?.split(" ").join("-")}`}
    >
      {title ? (
        <div className="h3 mb-0 font-normal text-dark">
          <HtmlFragment>{title}</HtmlFragment>
        </div>
      ) : null}
      {description ? (
        <div
          className={clsx("text-body-lg py-24 font-normal", {
            "text-dark": ctaVersion,
            "text-primary": !ctaVersion,
          })}
        >
          <HtmlFragment>{description}</HtmlFragment>
        </div>
      ) : null}
      {get(cta, "link.href") ? <Cta {...cta} /> : null}
    </div>
  ) : (
    <div
      className="border-l-8 border-grey-600"
      key={`${title?.split(" ").join("-")}`}
    >
      <div className="h3 mb-0 pl-24 font-normal text-primary">
        {description ? <HtmlFragment>{description}</HtmlFragment> : null}
        {get(cta, "link.href") ? <Cta {...cta} /> : null}
      </div>
    </div>
  );
}

export default Note;
