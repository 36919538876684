import { matcher } from "matcher";
import _url from "url";

import {
  EMAIL_REGEX,
  FILE_REGEX,
  HTTPS_REGEX,
  NO_PROTOCOL_REGEX,
  PHONE_NUMBER_REGEX,
} from "./regex";

export function getFileTypeForPath(href) {
  const matched = href && href.match(FILE_REGEX);

  return matched && matched[0];
}

export function getHrefForPhoneNumber(phoneNumber) {
  const href = phoneNumber.replace(
    PHONE_NUMBER_REGEX,
    (match, ...p) =>
      `tel:${p[0] || ""}${p[1] || ""}${p[2] || ""}${p[3] || ""}${
        p[4] ? "," + p[4] : ""
      }`
  );

  return href;
}

export function getHrefForEmailAddress(emailAddress) {
  return `mailto:${emailAddress}`;
}

export function isEmailAddress(href) {
  return EMAIL_REGEX.test(href);
}

export function isExternalUrl(url, hostUrl) {
  if (!HTTPS_REGEX.test(url) && !NO_PROTOCOL_REGEX.test(url)) return false;
  return url.startsWith(hostUrl);
}

export function isPhoneNumber(phoneNumber) {
  return PHONE_NUMBER_REGEX.test(phoneNumber);
}

export function isExternalLinkUrl(url, internalHosts) {
  const parsedUrl = _url.parse(url);

  const { host, protocol } = parsedUrl;

  if (protocol && !protocol.match(/^https?:$/)) return true;
  if (host && !matcher(host, internalHosts)[0]) return true;
  return false;
}
