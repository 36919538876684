import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useHedgingContext } from "@/context/betaContext";
import { useAuth0 } from "@auth0/auth0-react";
import isEmpty from "lodash.isempty";
import { useEffect, useState } from "react";
import { formatDate } from "@/lib/text";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function TestBarGraphHoldings(props) {
  const { apiData } = props;

  // api data check
  const [lineJson, setLineJson] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    setGraphData(lineJson);
  }, [lineJson]);

  const sortPerformance =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData?.sort(
          (a, b) =>
            new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
        )
      : [];

  // const labels = [
  //   `As of: ${formatDate(sortPerformance?.[0].MARKET_CLOSE_DATE)}`,
  //   `As of: ${formatDate(sortPerformance?.[sortPerformance.length - 1].MARKET_CLOSE_DATE)}`,
  // ];

  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const labels = [
    `As of: ${formatDate(sortPerformance?.[0]?.CREATED_DATE)}`,

    `As of: ${formatDate(
      sortPerformance?.[0]?.CREATED_DATE ===
        sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE
        ? addDays(
            sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE,
            1
          )
        : sortPerformance?.[sortPerformance.length - 1]?.CREATED_DATE
    )}`,
  ];

  const HEDGED_TOTAL = Math.ceil(sortPerformance?.[0]?.UNHEDGED_TOTAL);
  const REQUIREDINVESTMENT = Math.ceil(
    sortPerformance?.[0]?.REQUIREDINVESTMENT
  );
  const second_HEDGED_TOTAL = Math.ceil(
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  const second_REQUIREDINVESTMENT = Math.ceil(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL -
      sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  // Check if MARKET_CLOSE_DATE is valid
  const givenDate =
    sortPerformance?.length > 0 &&
    !isNaN(new Date(sortPerformance[0]?.MARKET_CLOSE_DATE))
      ? new Date(sortPerformance[0]?.MARKET_CLOSE_DATE)
      : null;
  if (givenDate) {
    givenDate?.setDate(givenDate?.getDate() + 1);
  }

  // const givenDate = new Date(sortPerformance[0]?.MARKET_CLOSE_DATE);
  // givenDate?.setDate(givenDate?.getDate() + 1);

  // Current date
  const currentDate = new Date();

  const dataHoldingsRequiredInvestment =
    givenDate?.toISOString().split("T")[0] ===
    currentDate?.toISOString().split("T")[0]
      ? [REQUIREDINVESTMENT, 0]
      : [REQUIREDINVESTMENT, second_REQUIREDINVESTMENT];

  const dataHedgeTotal =
    givenDate?.toISOString().split("T")[0] ===
    currentDate?.toISOString().split("T")[0]
      ? [HEDGED_TOTAL, 0]
      : [HEDGED_TOTAL, second_HEDGED_TOTAL];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Hedge",
        data: dataHoldingsRequiredInvestment,
        backgroundColor: "#771B85",
        barPercentage: 0.97,
        categoryPercentage: 0.97,
      },
      {
        label: "Holdings",
        data: dataHedgeTotal,
        backgroundColor: "#415580 ",
        barPercentage: 0.97,
        categoryPercentage: 0.97,
      },
    ],
  };

  const topLabel = {
    id: "topLabel",
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {
        ctx,
        scales: { x, y },
      } = chart;
      chart.data.datasets[0].data.forEach((dataPoint, index) => {
        const datasetArray = [];
        chart.data.datasets.forEach((dataset) => {
          datasetArray.push(dataset.data[index]);
        });
        //sum array
        function totalSum(total, values) {
          return Math.ceil(total + values);
        }
        let sum = `$${datasetArray.reduce(totalSum, 0).toLocaleString()}`;
        ctx.font = "mazzardSemiBold";
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(
          sum,
          x.getPixelForValue(index),
          chart.getDatasetMeta(1).data[index].y - 10
        );
      });
    },
  };

  const options = {
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        itemSort: function (a, b) {
          return b.datasetIndex - a.datasetIndex;
        },
      },
      legend: {
        display: false,
        position: "top",
        align: "start",
      },
      datalabels: {
        display: true,
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: (value) => `$${value?.toLocaleString()}`,
      },
    },
    layout: {
      padding: {
        top: 40,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,

        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line on y-axis
        },
        ticks: {
          color: "#323232", // Set x-axis ticks color
          font: {
            family: "MazzardHBold",
            size: 14,
            lineHeight: 1.5,
          },
        },
      },
      y: {
        stacked: true,

        grid: {
          display: false,
        },
        border: {
          display: false, // Hide the border line on y-axis
        },
        ticks: {
          display: false,
          callback: (value) => `$${value.toLocaleString()}`,
        },
      },
    },
  };

  // check uploader

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type === "application/json") {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            // Debugging: Check file content before parsing

            // Removing any potential BOM characters
            let fileContent = e.target.result;
            if (fileContent.charCodeAt(0) === 0xfeff) {
              fileContent = fileContent.substring(1);
            }

            // Parse JSON
            const data = JSON.parse(fileContent);

            // Update state
            setLineJson(data);
            setJsonData(data); // Store data for displaying if needed
          } catch (error) {
            console.error("Error parsing JSON:", error);
            alert("Error parsing JSON file. Please ensure it is valid JSON.");
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          alert("Error reading file. Please try again.");
        };

        reader.readAsText(file);
      } else {
        alert("Please upload a valid JSON file.");
      }
    } else {
      alert("No file selected. Please choose a file.");
    }
  };

  const body = {
    UNHEDGED_TOTAL: 80.42,
    HEDGED_TOTAL: 112.84094,
    MARKET_CLOSE_DATE: "2024-06-11",
    REQUIREDINVESTMENT: 32.4209437064,
    CURRENT_HEDGE_TOTAL: 33.11408,
    CREATED_DATE: "6/12/2024 12:00:00 PM",
  };

  return (
    <div className="container text-left">
      <div className="py-20 font-mazzardSemiBold text-lg text-dark">
        Bar Graph Holdings :
      </div>
      <div className="bg-gray-100">
        <div className="pl-16 pt-8 font-mazzardSemiBold text-md text-dark">
          Portfolio Value
        </div>
        <div className="pb-8 pl-16">
          <span>
            <span className="inline-block h-[12px] w-[12px] bg-grey-600"></span>
            <span className="px-8 text-3xs text-grey-600">Value Ex-Hedge</span>
          </span>
          <span>
            <span className="inline-block h-[12px] w-[12px] bg-secondary"></span>
            <span className="b-10 px-8 text-3xs text-secondary">
              Hedge Value
            </span>
          </span>
        </div>
        <Bar data={data} options={options} plugins={[topLabel]} />
      </div>
      {/* <div className="text-center text-3xs">
        Exposure uses your holdings weighted beta average to calculate your beta
        value exposure.
      </div> */}
      {apiData ? (
        <>
          <div className="py-10 mt-20">
            <input type="file" accept=".json" onChange={handleFileChange} />
          </div>
          <div className="my-20 bg-gray-100 p-12">
            <pre>{JSON.stringify(body, null, 2)}</pre>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default TestBarGraphHoldings;
