import clsx from "clsx";

import HtmlFragment from "./html-fragment";
import isEmpty from "lodash.isempty";

const BASE_CLASS = "site-footer";

function SiteFooter(props) {
  let { disclosures, className = "" } = props;

  return props ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="bg-grey-200">
        {!isEmpty(disclosures?.text) ? (
          <div className="container py-32 ">
            <div className="text-body-xs font-normal text-dark">
              <HtmlFragment>{disclosures?.text}</HtmlFragment>
            </div>
          </div>
        ) : null}
        <div className="h-16 bg-grey-600"></div>
      </div>
    </div>
  ) : null;
}

export default SiteFooter;
