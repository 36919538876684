import React, { useEffect, useState } from "react";
import LineTitle from "./line-title";
import isEmpty from "lodash.isempty";
import LineChart from "./line-chart";
import BarGraph from "./bar-graph";
import BarGraphHoldings from "./bar-graph-holdings";
import { useAuth0 } from "@auth0/auth0-react";
import { useHedgingContext } from "@/context/betaContext";
import HtmlFragment from "./html-fragment";

function PerformanceGraph(props) {
  const { exposure, performance } = props;
  const [activeTab, setActiveTab] = useState("Exposure");

  const {
    tabDataChange,
    handleTabDataChange,
  } = useHedgingContext();

  useEffect(() => {
    tabDataChange && setActiveTab("Exposure");
    tabDataChange === true && handleTabDataChange(false);
  }, [tabDataChange, handleTabDataChange]);

  return (
    <div className="pb-48 pt-32">
      <div className="container">
        <div className="grid grid-cols-1 gap-x-24 gap-y-32 lg:grid-cols-2">
          {!isEmpty(exposure) ? (
            <div>
              {exposure?.title && (
                <LineTitle
                  text={exposure.title}
                  toolTipToggle={exposure?.toolTipToggle}
                  toolTipDetail={exposure?.toolTipDetail}
                  container={false}
                />
              )}
              <div className="py-28">
                <span>View : </span>
                <span
                  className={`cursor-pointer rounded-l-3xl px-12 py-8 text-2xs ${
                    activeTab === "Exposure"
                      ? "bg-secondary text-white"
                      : "bg-grey-300"
                  }`}
                  onClick={() => setActiveTab("Exposure")}
                >
                  Exposure
                </span>
                <span
                  className={`cursor-pointer rounded-r-3xl px-12 py-8 text-2xs ${
                    activeTab === "Holdings"
                      ? "bg-secondary text-white"
                      : "bg-grey-300"
                  }`}
                  onClick={() => setActiveTab("Holdings")}
                >
                  Portfolio
                </span>
              </div>
              {activeTab === "Exposure" && (
                <>
                  <BarGraph />
                  <div className="py-16 pl-32 text-3xs text-grey-600">
                    <HtmlFragment>{exposure.footnote}</HtmlFragment>
                  </div>
                </>
              )}
              {activeTab === "Holdings" && (
                <>
                  <BarGraphHoldings />
                  <div className="py-16 pl-32 text-3xs text-grey-600">
                    <HtmlFragment>{exposure.footnote}</HtmlFragment>
                  </div>
                </>
              )}
            </div>
          ) : null}
          {!isEmpty(performance) ? (
            <div>
              {performance?.title ? (
                <LineTitle
                  text={performance?.title}
                  toolTipToggle={performance?.toolTipToggle}
                  toolTipDetail={performance?.toolTipDetail}
                  tooltipLeft={performance?.tooltipLeft}
                  container={false}
                />
              ) : null}

              <div className="pt-24">
                {performance.header ? (
                  <div className="pb-16 font-mazzardBold text-md">
                    <HtmlFragment>{performance.header}</HtmlFragment>
                  </div>
                ) : null}
                {performance.description ? (
                  <div className="pb-16">
                    <HtmlFragment>{performance.description}</HtmlFragment>
                  </div>
                ) : null}
                <LineChart />
                {performance.footnote ? (
                  <div className="pb-16 text-3xs text-grey-600">
                    <HtmlFragment>{performance.footnote}</HtmlFragment>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PerformanceGraph;
