import React, { useState } from "react";
import clsx from "clsx";

import isEmpty from "lodash.isempty";
import map from "lodash.map";
import get from "lodash.get";

import HtmlFragment from "./html-fragment";
import Cta from "./cta";

const BASE_CLASS = "resources";

function Resources(props) {
  const { resourcesList, className = "", header, description, border } = props;

  return !isEmpty(props) ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="container grid grid-cols-12 gap-16 pb-64 pt-32 ipad:pb-80 ipad:pt-32">
        <div
          className={clsx(
            "col-span-12 grid gap-24 ipad:col-span-8 ipad:gap-32",
            {
              "border-t border-grey-600 pt-24 ipad:pt-32":
                border === "borderTop",
            }
          )}
        >
          {header ? <h2 className="text-grey-600">{header}</h2> : null}
          {description ? (
            <div className="text-body-lg text-dark">
              <HtmlFragment>{description}</HtmlFragment>
            </div>
          ) : null}
          {!isEmpty(resourcesList) ? (
            <div className="grid grid-cols-1 gap-24 ipad:grid-cols-2">
              {map(resourcesList, ({ title, time, cta, description }, i) => {
                return title || description ? (
                  <div
                    className="grid gap-24 bg-grey-200 px-32 py-44"
                    key={`${title?.split(" ").join("-")}-${i}`}
                  >
                    {title ? <h3 className="mb-0 text-dark">{title}</h3> : null}
                    {description ? (
                      <div className="text-body-lg">
                        <HtmlFragment>{description}</HtmlFragment>
                      </div>
                    ) : null}
                    {time ? (
                      <div className="text-body-sm mt-64 font-mazzardBold">
                        {time}
                      </div>
                    ) : null}
                    {!!get(cta, "link.href") ||
                    (!!get(cta, "link.externalHref") && cta?.link?.external) ? (
                      <Cta {...cta} />
                    ) : null}
                  </div>
                ) : null;
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}

export default Resources;
