import clsx from "classnames";

import { Link, Icon } from "./*";
import get from "lodash.get";

const BASE_CLASS = "cta";

function Cta(props) {
  let {
    link,
    children,
    className,
    block,
    flex,
    center,
    icon = false,
    contentType: contenttype,
    ...rest
  } = props;

  const openInNewTab = link?.target === "_blank";
  const variants = {
    primary: "btn btn-primary",
    secondary: "btn btn-secondary",
    "secondary-icon": "btn btn-secondary btn-arrow gap-12",
    download: "btn btn-download",
    "link-primary": "link link-primary",
    "link-icon": "link link-icon gap-12",
    "link-external": "link link-external gap-12 items-center",
    "link-default":"link underline link-primary font-mazzard"
  };

  const inlineBlock =
    (className === "primary" ||
      className === "secondary" ||
      className === "download" ||
      className === "link-primary" || className === "link-default") &&
    !block;
  const inlineFlex =
    (className === "secondary-icon" ||
      className === "link-icon" ||
      className === "link-external") &&
    !flex;

  return get(link, "href") || (get(link, "externalHref") && link?.external) ? (
    <Link
      href={link?.external ? link?.externalHref : link?.href}
      className={clsx(BASE_CLASS, variants[className], {
        block: block,
        flex: flex,
        "inline-flex text-sm": inlineFlex,
        "inline-block": inlineBlock,
        "text-center": center,
      })}
      contenttype={contenttype}
      openInNewTab={openInNewTab}
      {...rest}
    >
      <span className={clsx(`${BASE_CLASS}__label`)}>{children}</span>

      {icon ? (
        <span className={clsx(`${BASE_CLASS}__icon`)}>
          <Icon name="arrow-right-purple" />
        </span>
      ) : null}

      {link?.external ? (
        <span className={clsx(`${BASE_CLASS}__icon`)}>
          <Icon name="external-link-dark" />
        </span>
      ) : null}
    </Link>
  ) : null;
}

export default Cta;
