import React, { useState } from "react";
import clsx from "clsx";

import isEmpty from "lodash.isempty";
import map from "lodash.map";
import get from "lodash.get";

import {
  CodeBlock,
  MediaBlock,
  Cta,
  PageHeader,
  Note,
  HtmlFragment,
} from "./*";

const BASE_CLASS = "accordion";

function Accordion(props) {
  const {
    accordionList,
    className = "",
    splitSection,
    note,
    pageHeader,
    cta,
  } = props;

  const [isOpen, setIsOpen] = useState([]);

  const handleIsOpen = (id) => {
    if (isOpen.includes(id))
      return setIsOpen((prev) => prev.filter((e) => e !== id));
    setIsOpen((prev) => [...prev, id]);
  };

  return !isEmpty(accordionList) ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="container">
        {pageHeader ? <PageHeader {...pageHeader} /> : null}
        <div className="gap-x-1 grid grid-cols-12 place-items-start md:gap-x-16">
          <div
            className={clsx("col-span-12 grid grid-cols-1 gap-y-16", {
              "ipad:col-span-8": splitSection,
            })}
          >
            {map(accordionList, ({ title, id, body, mediaBlock }, i) => {
              let accordionId = id || `${title?.split(" ").join("-")}-${i}`;

              return title && accordionId && body ? (
                <div key={`${accordionId}-${i}`}>
                  {title ? (
                    <h2>
                      <button
                        className="h3 mb-16 flex w-full items-center justify-between border-b-2 border-b-dark py-2 pb-16 text-left font-normal text-grey-600"
                        onClick={() => handleIsOpen(accordionId)}
                        aria-expanded={isOpen.includes(accordionId)}
                        aria-controls={`accordion-text-${accordionId}`}
                        aria-label={`Toggle accordion for ${title}`}
                      >
                        <span>{title}</span>
                        <svg
                          className="ml-8 shrink-0 fill-grey-600"
                          width="16"
                          height="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            y="7"
                            width="16"
                            height="2"
                            rx="1"
                            className={`origin-center transform transition duration-200 ease-out ${
                              isOpen.includes(accordionId) && "!rotate-180"
                            }`}
                          />
                          <rect
                            y="7"
                            width="16"
                            height="2"
                            rx="1"
                            className={`origin-center rotate-90 transform transition duration-200 ease-out ${
                              isOpen.includes(accordionId) && "opacity-0"
                            }`}
                          />
                        </svg>
                      </button>
                    </h2>
                  ) : null}
                  {body ? (
                    <div
                      id={`accordion-text-${accordionId}`}
                      role="region"
                      aria-labelledby={`accordion-title-${accordionId}`}
                      className={`grid overflow-hidden text-md text-dark transition-all duration-300 ease-in-out ${
                        isOpen.includes(accordionId)
                          ? "grid-rows-[1fr] opacity-100"
                          : "grid-rows-[0fr] opacity-0"
                      }`}
                    >
                      <div className="overflow-hidden">
                        <div>
                          {typeof body === "object" && body !== null ? (
                            <CodeBlock code={body} />
                          ) : (
                            <HtmlFragment>{body}</HtmlFragment>
                          )}
                          {!isEmpty(mediaBlock) ? (
                            <MediaBlock {...mediaBlock} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null;
            })}
            {get(cta, "link.href") ? (
              <div className="mb-24 mt-16 ipad:mb-0">
                <Cta {...cta} />
              </div>
            ) : null}
          </div>
          <div
            className={clsx("col-span-12", {
              "ipad:col-span-4": splitSection,
            })}
          >
            {!isEmpty(note) ? <Note note={note} /> : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Accordion;
