import React, { useEffect, useState } from "react";
import { useHedgingContext } from "@/context/betaContext";

import isEmpty from "lodash.isempty";

function ProgressBar(props) {
  const { text, upSideDown, apiData } = props;
  const { hedgePercent } = useHedgingContext();

  // api data check
  const [lineJson, setLineJson] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    setGraphData(lineJson);
  }, [lineJson]);

  const sortPerformance =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData.sort(
          (a, b) =>
            new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
        )
      : null;

  const initialHedgedExposure = sortPerformance?.[0]?.HEDGEDEXPOSURE;
  const intialUnhedgedExposure = sortPerformance?.[0]?.UNHEDGED_EXPOSURE;
  const currentHedgedExposure =
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_EXPOSURE;
  const currentUnhedgedExposure =
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_UNHEDGE_EXPOSURE;

  const initialCount =
    (initialHedgedExposure / (initialHedgedExposure + intialUnhedgedExposure)) *
    100;

  const currentCount =
    (currentHedgedExposure / currentUnhedgedExposure) * 100 > 0
      ? (currentHedgedExposure / currentUnhedgedExposure) * 100
      : 0.1;

  const initialTargetPosition = initialCount ? Math.round(initialCount) : 50;
  const currentExposurePosition = currentCount ? Math.round(currentCount) : 50;

  const targetRangeStartPosition = initialTargetPosition - hedgePercent?.below;
  const targetRangeWidth =
    initialTargetPosition + hedgePercent?.above - targetRangeStartPosition;

  const initialTarget = initialCount ? Math.round(initialCount) : "50";
  const currentExposure = currentCount ? Math.round(currentCount) : "50";

  const targetRangeStart = 45;
  const targetRangeEnd = 55;

  // check uploader

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type === "application/json") {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            // Debugging: Check file content before parsing

            // Removing any potential BOM characters
            let fileContent = e.target.result;
            if (fileContent.charCodeAt(0) === 0xfeff) {
              fileContent = fileContent.substring(1);
            }

            // Parse JSON
            const data = JSON.parse(fileContent);

            // Update state
            setLineJson(data);
            setJsonData(data); // Store data for displaying if needed
          } catch (error) {
            console.error("Error parsing JSON:", error);
            alert("Error parsing JSON file. Please ensure it is valid JSON.");
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          alert("Error reading file. Please try again.");
        };

        reader.readAsText(file);
      } else {
        alert("Please upload a valid JSON file.");
      }
    } else {
      alert("No file selected. Please choose a file.");
    }
  };

  const body = {
    MARKET_CLOSE_DATE: "2024-06-11",
    HEDGEDEXPOSURE: 64.13511084,
    UNHEDGED_EXPOSURE: 28.81432516,
    CURRENT_HEDGE_EXPOSURE: 50,
    CURRENT_UNHEDGE_EXPOSURE: 92.949436,
    CREATED_DATE: "6/12/2024 12:00:00 PM",
  };

  return (
    <div className={upSideDown ? "container pb-40 pt-20" : ""}>
      <div className="pb-40 pt-20 font-mazzardSemiBold text-lg text-dark">
        Test Progree Bar :
      </div>
      <div className="flex items-center gap-8">
        <div className="text-2xs text-black">0%</div>
        <div className="relative h-24 w-full overflow-x-clip bg-blue-50">
          {/* Full Range Background */}
          <div className="absolute h-full w-full bg-grey-300"></div>

          {/* Target Exposure Range */}
          <div
            className="absolute h-full bg-gray-400"
            style={{
              left: `${targetRangeStartPosition}%`,
              width: `${targetRangeWidth}%`,
            }}
          ></div>

          {/* Initial Target Exposure */}
          <div
            className={`absolute bg-grey-600 ${
              upSideDown ? "top-[10px] h-[18px]" : "top-4 h-full"
            }`}
            style={{ left: `${initialTargetPosition}%`, width: "4px" }}
          ></div>

          {/* Current Exposure */}
          <div
            className={`absolute bg-purple-100 ${
              upSideDown ? "top-[-10px] h-[18px]" : "top-4 h-full"
            }`}
            style={{ left: `${currentExposurePosition}%`, width: "4px" }}
          ></div>

          {/* Ticks */}
          <div
            className="absolute flex h-full items-end justify-center"
            style={{ left: `${initialTargetPosition}%` }}
          >
            <div className="w-0.5 h-2 bg-black"></div>
          </div>
          <div
            className="absolute flex h-full items-end justify-center"
            style={{ left: `${currentExposurePosition}%` }}
          >
            <div className="w-0.5 h-2 bg-black"></div>
          </div>

          {/* Labels */}
          <div
            className="absolute top-full mx-4 mt-4 text-2xs text-grey-600"
            style={{ left: `calc(${initialTargetPosition}% - 1rem)` }}
          >
            {initialTarget}%
          </div>
          <div
            className={`absolute text-2xs text-purple-100 ${
              upSideDown ? "top-[-28px] mx-4" : "top-full mt-4"
            }`}
            style={{ left: `calc(${currentExposurePosition}% - 1rem)` }}
          >
            {currentExposure}%
          </div>

          {/* Legend 
         <div className="mt-8 flex space-x-4 text-xs">
          <div className="flex items-center">
            <div className="mr-1 h-4 w-4 bg-purple-600"></div>
            <span>Current Exposure</span>
          </div>
          <div className="flex items-center">
            <div className="mr-1 h-4 w-4 bg-blue-600"></div>
            <span>Initial Target Exposure</span>
          </div>
          <div className="flex items-center">
            <div className="mr-1 h-4 w-4 bg-gray-200"></div>
            <span>Target Exposure Range</span>
          </div>
        </div> */}
        </div>
        <div className="text-2xs text-black">100%</div>
      </div>
      <div className="pb-20 pt-36">
        <span>
          <span className="inline-block h-12 w-12 bg-secondary"></span>
          <span className="px-8 text-2xs text-secondary">Current Exposure</span>
        </span>
        <span>
          <span className="inline-block h-12 w-12 bg-grey-600"></span>
          <span className="b-10 px-8 text-2xs">Target Exposure</span>
        </span>
        <span>
          <span className="inline-block h-12 w-12 bg-gray-400"></span>
          <span className="b-10 px-8 text-2xs">Target Exposure Range</span>
        </span>
      </div>
      <div className="font-mazzardSemiBold text-sm text-dark">
        Above and Below are configured at 5% each.
      </div>
      {apiData ? (
        <>
          <div className="py-10 mt-20">
            <input type="file" accept=".json" onChange={handleFileChange} />
          </div>
          <div className="my-20 bg-gray-100 p-12">
            <pre>{JSON.stringify(body, null, 2)}</pre>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ProgressBar;
