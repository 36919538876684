import React, { useEffect, useState } from "react";
import { useHedgingContext } from "@/context/betaContext";

import isEmpty from "lodash.isempty";
import { useWindowSize } from "react-use";

import get from "lodash.get";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";

function ProgressBar(props) {
  const { upSideDown } = props;
  const {
    graphData,
    hedgePercent,
    getAllPortfolio,
    getHedgeData,
    betaValue,
    handleSettingTabActive,
  } = useHedgingContext();
  const mobile = get(useWindowSize(), "width") < 500 ? true : false;

  const { isAuthenticated } = useAuth0();

  const [targetRangeWidth, setTargetRangeWidth] = useState(0);

  const router = useRouter();

  const sortedData = getAllPortfolio?.sort(
    (a, b) =>
      new Date(b.DATE_CREATED_UPDATED) - new Date(a.DATE_CREATED_UPDATED)
  );

  const checkId = router?.query?.portfolio_id
    ? Number(router?.query?.portfolio_id)
    : sortedData?.[0]?.ID;

  const filteredData = sortedData?.filter((item) => item.ID == checkId);

  const sortPerformance =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData.sort(
          (a, b) =>
            new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
        )
      : null;

  // condition for hedge type :
  const conditionForHedgeType =
    getHedgeData?.hedgeholding?.HEDGED_TYPE === "edit" ||
    getHedgeData?.hedgeholding?.HEDGED_TYPE === "rebalance" ||
    getHedgeData?.hedgeholding?.HEDGED_TYPE === "modify" ||
    getHedgeData?.hedgeholding?.HEDGED_TYPE === "new";

  const initialHedgedExposure = sortPerformance?.[0]?.HEDGEDEXPOSURE;
  const intialUnhedgedExposure = sortPerformance?.[0]?.UNHEDGED_EXPOSURE;

  const currentHedgedExposure =
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_EXPOSURE;

  const currentUnhedgedExposure =
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_UNHEDGE_EXPOSURE;

  const initialCount =
    (initialHedgedExposure / (initialHedgedExposure + intialUnhedgedExposure)) *
    100;

  const apiExposure =
    getHedgeData?.hedgeholding?.HEDGED_UNITS *
    getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE *
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  // const currentHedgePercentage = (apiExposure / betaValue) * 100;
  // const currentCount =
  //   (currentHedgedExposure / currentUnhedgedExposure) * 100 > 0
  //     ? (currentHedgedExposure / currentUnhedgedExposure) * 100
  //     : 0.1;

  const currentCount = (apiExposure / betaValue) * 100;

  const initialTargetPosition = Math.round(initialCount);
  const currentExposurePosition1 = Math.round(currentCount);

  const currentExposurePosition = !conditionForHedgeType
    ? initialTargetPosition
    : currentExposurePosition1;

  // useEffect(() => {
  //   if (filteredData) {
  //     handleHedgePercent({
  //       above: filteredData?.[0]?.RANGE_ABOVE,
  //       below: filteredData?.[0]?.RANGE_BELOW,
  //     });
  //   } else if(checkDropBelow || checkDropAbove){
  //     handleHedgePercent({
  //       above: filteredData?.[0]?.RANGE_ABOVE,
  //       below: filteredData?.[0]?.RANGE_BELOW,
  //     });
  //   }
  // }, [checkDropAbove, checkDropBelow, filteredData, handleHedgePercent]);

  // const targetRangeStartPosition =
  //   initialTargetPosition -
  //   (filteredData || !tabDataChange
  //     ? checkDropBelow
  //       ? hedgePercent?.below
  //       : checkDropAbove
  //         ? hedgePercent?.above
  //         : filteredData?.[0]?.RANGE_BELOW
  //     : hedgePercent?.below);

  // const targetRangeWidthUpdate =
  //   initialTargetPosition +
  //   (filteredData || !tabDataChange
  //     ? checkDropBelow
  //       ? hedgePercent?.below
  //       : checkDropAbove
  //         ? hedgePercent?.above
  //         : filteredData?.[0]?.RANGE_ABOVE
  //     : hedgePercent?.above) -
  //   targetRangeStartPosition;

  // update gray area range according to dropdown change
  const targetRangeStartPosition =
    initialTargetPosition -
    (filteredData ? filteredData[0]?.RANGE_BELOW : hedgePercent?.below);

  const targetRangeWidthUpdate =
    initialTargetPosition +
    (filteredData ? filteredData[0]?.RANGE_ABOVE : hedgePercent?.above) -
    targetRangeStartPosition;

  useEffect(() => {
    if (isAuthenticated) {
      setTargetRangeWidth(targetRangeWidthUpdate);
    }
  }, [isAuthenticated, targetRangeWidthUpdate]);

  const initialTarget = Math.round(initialCount);
  const currentExposure = Math.round(currentCount);

  // const targetRangeStart = 45;
  // const targetRangeEnd = 55;

  const handleRouteSetting = () => {
    handleSettingTabActive(true);
  };

  const calculateMaxRange = () => {
    const maxValues = [
      initialTarget,
      currentExposure,
      targetRangeStartPosition + targetRangeWidth,
    ].filter((val) => !isNaN(val));
    const maxValue = Math.max(...maxValues);
    return Math.ceil(maxValue / 100) * 100;
  };

  const getPositionStyle = (value) => {
    const position = (value / calculateMaxRange()) * 100;
    if (position <= 3) return `calc(${position}% - 0.3rem)`;
    if (position >= 97) return `calc(${position}% - 1.7rem)`;
    if (position >= 100) return `calc(100% - 2.5rem)`;
    return `calc(${position}% - 1rem)`;
  };

  return (
    <div className={upSideDown ? "pt-20" : ""}>
      <div className="flex items-center gap-8">
        <div className="text-2xs text-black">0%</div>
        <div className="relative h-24 w-full overflow-x-clip bg-blue-50">
          {/* Full Range Background */}
          <div className="absolute h-full w-full bg-grey-300"></div>

          {/* Target Exposure Range */}
          <div
            className="absolute h-full bg-gray-400"
            style={{
              // left: `${targetRangeStartPosition > 100 ? 90 : targetRangeStartPosition}%`,
              // width: `${targetRangeWidth}%`,
              left: `${(targetRangeStartPosition / calculateMaxRange()) * 100}%`,
              width: `${(targetRangeWidth / calculateMaxRange()) * 100}%`,
            }}
          ></div>

          {/* Initial Target Exposure */}
          <div
            className={`absolute bg-grey-600 ${
              upSideDown ? "top-[10px] h-[18px]" : "top-4 h-full"
            }`}
            style={{
              // left: `${initialTargetPosition > 100 ? 95 : initialTargetPosition}%`,
              left: `${(initialTargetPosition / calculateMaxRange()) * 100}%`,
              width: "4px",
            }}
          ></div>

          {/* Current Exposure */}
          <div
            className={`absolute bg-purple-100 ${
              upSideDown ? "top-[-10px] h-[18px]" : "top-4 h-full"
            }`}
            style={{
              // left: `${
              //   !conditionForHedgeType
              //     ? initialTarget > 100
              //       ? 95
              //       : initialTarget
              //     : currentExposurePosition > 100
              //       ? 95
              //       : currentExposurePosition
              // }%`,

              left: `${
                ((!conditionForHedgeType
                  ? initialTarget
                  : currentExposurePosition) /
                  calculateMaxRange()) *
                100
              }%`,
              width: "4px",
            }}
          ></div>

          {/* Ticks */}
          <div
            className="absolute flex h-full items-end justify-center"
            // style={{ left: `${initialTargetPosition}%` }}
            style={{
              left: `${(initialTargetPosition / calculateMaxRange()) * 100}%`,
            }}
          >
            <div className="w-0.5 h-2 bg-black"></div>
          </div>
          <div
            className="absolute flex h-full items-end justify-center"
            // style={{
            //   left: `${currentExposurePosition > 100 ? 95 : currentExposurePosition}%`,
            // }}
            style={{
              left: `${(currentExposurePosition / calculateMaxRange()) * 100}%`,
            }}
          >
            <div className="w-0.5 h-2 bg-black"></div>
          </div>

          {/* Labels */}
          <div
            className="absolute top-full mt-4  text-2xs font-black text-grey-600"
            style={{
              // left:
              //   [1, 2, 3].includes(initialTargetPosition) && mobile
              //     ? `calc(${initialTargetPosition}% - 0.3rem)`
              //     : [1, 2, 3].includes(initialTargetPosition) && !mobile
              //       ? `calc(${initialTargetPosition}% - 0.4rem)`
              //       : [97, 98, 99].includes(initialTargetPosition) && mobile
              //         ? `calc(${initialTargetPosition}% - 1.7rem)`
              //         : [97, 98, 99].includes(initialTargetPosition) && !mobile
              //           ? `calc(${initialTargetPosition}% - 1.5rem)`
              //           : initialTargetPosition == 0
              //             ? `calc(${initialTargetPosition}% - 0rem)`
              //             : initialTargetPosition >= 100
              //               ? `calc(${initialTargetPosition > 100 ? 100 : initialTargetPosition}% - 2.5rem)`
              //               : `calc(${initialTargetPosition}% - 1rem)`,
              left: getPositionStyle(initialTargetPosition),
            }}
          >
            {initialTarget}%
          </div>
          <div
            className={`absolute text-2xs font-black text-purple-100 ${
              upSideDown ? "top-[-28px]" : "top-full mt-4"
            }`}
            style={{
              // left:
              //   [1, 2, 3].includes(currentExposurePosition) && mobile
              //     ? `calc(${
              //         !conditionForHedgeType
              //           ? initialTarget
              //           : currentExposurePosition
              //       }% - 0.3rem)`
              //     : [1, 2, 3].includes(currentExposurePosition) && !mobile
              //       ? `calc(${
              //           !conditionForHedgeType
              //             ? initialTarget
              //             : currentExposurePosition
              //         }% - 0.4rem)`
              //       : currentExposurePosition == 0
              //         ? `calc(${
              //             !conditionForHedgeType
              //               ? initialTarget
              //               : currentExposurePosition
              //           }% - 0rem)`
              //         : [97, 98, 99].includes(currentExposurePosition) && mobile
              //           ? `calc(${
              //               !conditionForHedgeType
              //                 ? initialTarget
              //                 : currentExposurePosition
              //             }% - 1.7rem)`
              //           : [97, 98, 99].includes(currentExposurePosition) &&
              //               !mobile
              //             ? `calc(${
              //                 !conditionForHedgeType
              //                   ? initialTarget
              //                   : currentExposurePosition
              //               }% - 1.5rem)`
              //             : currentExposurePosition >= 100
              //               ? `calc(${
              //                   !conditionForHedgeType
              //                     ? initialTarget
              //                     : currentExposurePosition > 100
              //                       ? 100
              //                       : currentExposurePosition
              //                 }% - 2.5rem)`
              //               : `calc(${
              //                   !conditionForHedgeType
              //                     ? initialTarget
              //                     : currentExposurePosition
              //                 }% - 1rem)`,
              left: getPositionStyle(
                !conditionForHedgeType ? initialTarget : currentExposurePosition
              ),
            }}
          >
            {!conditionForHedgeType ? initialTarget : currentExposure}%
          </div>
        </div>
        <div className="text-2xs text-black">
          {/* {initialTarget > 100
            ? `${Math.ceil(initialTarget / 100) * 100}%`
            : "100%"} */}
          {`${calculateMaxRange()}%`}
        </div>
      </div>
      <div className="flex items-center pb-20 pt-36">
        <div>
          <span className="inline-block h-12 w-12 bg-secondary"></span>
          <span className="px-8 text-2xs text-secondary">Current Exposure</span>
        </div>
        <div>
          <span className="inline-block h-12 w-12 bg-grey-600"></span>
          <span className="b-10 px-8 text-2xs">Target Exposure</span>
        </div>
        <div>
          <span className="inline-block h-12 w-12 bg-gray-400"></span>
          <span className="b-10 px-8 text-2xs">Target Exposure Range</span>
        </div>
        <div>
          <button
            className="text-2xs text-blue-200 underline"
            onClick={handleRouteSetting}
          >
            (Update)
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
