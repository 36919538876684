import clsx from "clsx";
import { useWindowSize } from "react-use";
import get from "lodash.get";
import HtmlFragment from "./html-fragment";
import Image from "next/image";
const BASE_CLASS = "media-block";
function MediaBlock(props) {
  let {
    className = "",
    media,
    video,
    embeddedVideo,
    embeddedAltText,
    desktopImage,
    mobileImage,
    bgImage,
    description,
    imageSet,
    internal,
    splitSection,
    footNote,
    title,
    imageSubDescription,
    imageDescription,
  } = props;
  const mobile = get(useWindowSize(), "width") < 1330 ? true : false;
  return props ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div
        className={clsx({
          "bg-grey-200 bg-[url('/images/image-mobile.webp')] bg-cover bg-right bg-no-repeat ipad:bg-[url('/images/table-bg.webp')]":
            bgImage,
          relative: imageSet,
        })}
      >
        <div
          className={clsx("grid grid-cols-12 gap-16", {
            container: !internal,
            "overflow-x-scroll 2xl:overflow-x-auto": imageSet,
            "items-center": media === "image-with-title",
            "items-start": !(media === "image-with-title"),
            "items-center": className,
          })}
        >
          {media === "Video" && get(video, "url") ? (
            <div
              className={clsx("col-span-12 my-16", {
                "ipad:col-span-8": splitSection,
              })}
            >
              <video
                width="100%"
                height="100%"
                autoPlay={true}
                muted={true}
                playsInline={true}
                controls={true}
                loop={true}
              >
                <source
                  src={`${video.url}?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&autoplay=1&playsinline`}
                  type="video/mp4"
                />
              </video>
              {footNote && (
                <div className="text-body-xxs pb-16 pt-16 text-grey-600">
                  {footNote}
                </div>
              )}
            </div>
          ) : media === "Gif" ? (
            <div
              className={clsx(
                "col-span-12 my-16 flex w-full flex-col justify-items-center bg-white",
                {
                  "ipad:col-span-8": splitSection,
                }
              )}
            >
              <Image
                layout="responsive"
                width={100}
                height={100}
                src={desktopImage?.url}
                alt={desktopImage?.altText}
              />
              <hr className="h-[0.5px] w-[92%] bg-grey-700"></hr>
              {footNote && (
                <div className="text-body-xxs pb-16 pl-12 pt-16 text-grey-600">
                  {footNote}
                </div>
              )}
            </div>
          ) : media === "image-with-title" ? (
            <div
              className={clsx(
                "col-span-12 my-16 flex flex-col justify-items-center ",
                {
                  "ipad:col-span-8": splitSection,
                }
              )}
            >
              {title && (
                <div className="text-body-xl font-semibold text-grey-600">
                  {title}
                </div>
              )}
              {imageDescription && (
                <div className="text-body-lg mt-12 font-normal">
                  {imageDescription}
                </div>
              )}
              {imageSubDescription && (
                <div className="text-body-sm mb-16 mt-24 font-semibold text-dark">
                  {imageSubDescription}
                </div>
              )}
              {get(desktopImage, "url") ? (
                <Image
                  layout="responsive"
                  width={100}
                  height={100}
                  src={desktopImage?.url}
                  alt={desktopImage?.altText}
                />
              ) : null}
              {footNote && (
                <div className="text-body-xxs pb-16 pt-16 text-grey-600">
                  {footNote}
                </div>
              )}
            </div>
          ) : media === "Embedded Video" && !!embeddedVideo ? (
            <div
              className={clsx("col-span-12 my-16", {
                "ipad:col-span-8": splitSection,
              })}
            >
              <iframe
                className="h-[510px] w-full"
                src={`${embeddedVideo}?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&autoplay=1`}
                title={embeddedAltText || null}
                autoPlay="autoplay"
              />
            </div>
          ) : get(desktopImage, "url") || get(mobileImage, "url") ? (
            <>
              {get(desktopImage, "url") ? (
                <div
                  className={clsx(
                    "col-span-12 my-16 hidden flex-col justify-center ipad:flex",
                    {
                      "ipad:col-span-8": splitSection,
                    }
                  )}
                >
                  <Image
                    className={clsx({
                      "min-w-[856px] overflow-x-scroll": imageSet,
                    })}
                    src={desktopImage.url}
                    alt={desktopImage.altText}
                    layout="responsive"
                    width={100}
                    height={100}
                  />
                  {footNote && (
                    <div className="text-body-xxs pb-16 pt-16 text-grey-600">
                      {footNote}
                    </div>
                  )}
                </div>
              ) : null}
              {get(mobileImage, "url") ? (
                <div
                  className={clsx(
                    "col-span-12 mx-auto my-16 block ipad:hidden",
                    {
                      "ipad:col-span-8": splitSection,
                    }
                  )}
                >
                  <Image
                    className={clsx({
                      "min-w-[856px] overflow-x-scroll": imageSet,
                    })}
                    src={mobileImage.url}
                    alt={mobileImage.alt}
                    layout="responsive"
                    width={100}
                    height={100}
                  />
                  {footNote && (
                    <div className="text-body-xxs pb-16 pt-16 text-grey-600">
                      {footNote}
                    </div>
                  )}
                </div>
              ) : null}
            </>
          ) : null}
          {mobile && imageSet ? (
            <div className="absolute right-0 top-0 h-full w-40 bg-gradient-to-r from-transparent from-10% to-white to-90%"></div>
          ) : null}
          <div
            className={clsx("col-span-12", {
              "ipad:col-span-4": splitSection,
            })}
          >
            {description && !(media === "image-with-title") ? (
              <div className="pt-8 md:px-12 ipad:px-28 ipad:py-0 ipad:pt-16">
                <div className="text-md text-dark">
                  <HtmlFragment>{description}</HtmlFragment>
                </div>
              </div>
            ) : null}
            {description && media === "image-with-title" ? (
              <div className="pt-8 ipad:py-0 ipad:pt-16">
                <div className="text-md text-dark">
                  <HtmlFragment>{description}</HtmlFragment>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
export default MediaBlock;
