import clsx from "classnames";
import NextLink from "next/link";
import { useRouter } from "next/router";

import isEmpty from "lodash.isempty";
import map from "lodash.map";

import { HtmlFragment, Icon } from "./*";

const BASE_CLASS = "link-table";

function LinkTable(props) {
  let { className = "", linkTableList } = props;
  const router = useRouter();

  const roman = (number) => {
    let roman = "";
    let a;
    const romanNumList = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XV: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };

    Object.keys(romanNumList).map((key) => {
      a = Math.floor(number / romanNumList[key]);
      if (a >= 0) {
        for (let i = 0; i < a; i++) {
          roman += key;
        }
      }
      number = number % romanNumList[key];
    });

    return roman;
  };

  return (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="bg-grey-200 p-16">
        {!isEmpty(linkTableList) ? (
          <ol className="text-body-lg list-inside list-disc">
            {map(linkTableList, ({ label, description, to }, i) => {
              return to ? (
                <li
                  key={i}
                  className="grid grid-cols-1 gap-x-16 gap-y-32 border-b border-grey-400 pb-16 pt-16 first:pt-0 md:gap-32 ipad:grid-cols-3"
                >
                  <NextLink
                    scroll={false}
                    href={{
                      pathname: router?.query?.stencil?.[0],
                      query: { component: to },
                    }}
                    as={router?.query?.stencil?.[0]}
                    className="link-primary col-span-1 flex justify-between gap-12 font-mazzard font-normal"
                  >
                    {label ? (
                      <>
                        <div className="flex">
                          {/* <span>{roman(i + 1)}.</span> */}
                          <span>{i + 1}.</span>
                          <span className="pl-12">{label}</span>
                        </div>
                        <span className="icon">
                          <Icon name="arrow-down-white-purple" />
                        </span>
                      </>
                    ) : null}
                  </NextLink>
                  {description ? (
                    <div className="col-span-2 font-normal text-dark">
                      <HtmlFragment>{description}</HtmlFragment>
                    </div>
                  ) : null}
                </li>
              ) : null;
            })}
          </ol>
        ) : null}
      </div>
    </div>
  );
}

export default LinkTable;
