import clsx from "classnames";

import isEmpty from "lodash.isempty";

import {
  HtmlFragment,
  Note,
  Cta,
  PageHeader,
  LinkTable,
  Table,
  MediaBlock,
  NextBlock,
  FeatureTable,
} from "./*";
import get from "lodash.get";

const BASE_CLASS = "rich-text";

function RichText(props) {
  let {
    anchorId = "",
    description,
    note,
    className = "",
    pageHeader,
    cta,
    linkTable,
    mediaBlock,
    table,
    nextBlock,
    featureTable,
  } = props;

  const paddingBottom =
    !isEmpty(cta) ||
    !isEmpty(linkTable) ||
    !isEmpty(table) ||
    !isEmpty(mediaBlock) ||
    !isEmpty(nextBlock) ||
    !isEmpty(featureTable);

  return (
    <div className={clsx(BASE_CLASS, className)} id={anchorId}>
      <div className="container">
        {pageHeader ? <PageHeader {...pageHeader} /> : null}
        <div className="grid grid-cols-12 gap-16">
          <div
            className={clsx("col-span-12 ipad:col-span-8", {
              "pb-24 md:pb-32": paddingBottom,
            })}
          >
            {description && (
              <div className="list-inside text-md font-normal text-dark">
                <HtmlFragment>{description}</HtmlFragment>
              </div>
            )}
            {get(cta, "link.href") && (
              <div className="py-32 text-center">
                <Cta {...cta} />
              </div>
            )}
            {!isEmpty(linkTable) ? <LinkTable {...linkTable} /> : null}
            {!isEmpty(table) ? <Table {...table} /> : null}
            {!isEmpty(mediaBlock) ? <MediaBlock {...mediaBlock} /> : null}
            {!isEmpty(nextBlock) ? <NextBlock {...nextBlock} /> : null}
            {!isEmpty(featureTable) ? <FeatureTable {...featureTable} /> : null}
          </div>
          {!isEmpty(note) ? (
            <div className="col-span-12 ipad:col-span-4">
              <Note note={note} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default RichText;
