import React from "react";
import { Tooltip, HtmlFragment as HF, Icon, RangeSlider as RS } from "./*";

function LineTitle(props) {
  const {
    text,
    toolTipToggle,
    toolTipDetail,
    container,
    tooltipLeft,
    tabToggle,
    tabToggleData,
    tabCount,
  } = props;

  return (
    <div className={container && "container"}>
      <div className="flex items-center gap-x-8">
        {toolTipToggle && toolTipDetail ? (
          <Tooltip
            info={toolTipDetail}
            tooltipLeft={tooltipLeft}
            tabToggleData={tabToggleData}
            tabToggle={tabToggle}
            tabCount={tabCount}
            size="lg"
          >
            <div className="font-mazzardSemiBold text-md text-secondary">
              {text}
            </div>
          </Tooltip>
        ) : (
          <div className="font-mazzardSemiBold text-md text-secondary">
            {text}
          </div>
        )}
        <div className="h-2 w-full flex-1 bg-secondary"></div>
      </div>
    </div>
  );
}

export default LineTitle;
