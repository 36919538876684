import { useState, useRef, useEffect, useCallback } from "react";
import clsx from "clsx";

function RangeSlider(props) {
  const {
    min,
    max,
    initialValue,
    onChange,
    width,
    step = 5,
    withLabel = false,
    disabled = false,
  } = props;
  const sliderRef = useRef(null);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const moveSliderPosition = useCallback(
    (event) => {
      const sliderBoundingClientRect =
        sliderRef.current?.getBoundingClientRect();

      if (sliderBoundingClientRect) {
        const posX =
          (event.clientX || event.touches[0].clientX) -
          sliderBoundingClientRect.left;
        const totalWidth = sliderBoundingClientRect.width;

        let selectedValue = Math.round((posX / totalWidth) * (max - min) + min);
        selectedValue = Math.max(min, selectedValue);
        selectedValue = Math.min(max, selectedValue);

        setCurrentValue(selectedValue);
      }
    },
    [max, min]
  );

  // Mouse events
  const onMouseUp = useCallback(() => {
    onChange(currentValue);
    setIsDragging(false);
  }, [currentValue, onChange]);

  const onMouseMove = useCallback(
    (event) => {
      if (isDragging) {
        moveSliderPosition(event);
      }
    },
    [isDragging, moveSliderPosition]
  );

  const onMouseDown = (event) => {
    moveSliderPosition(event);
    setIsDragging(true);
  };

  // Keyboard events
  const onKeyDown = (event) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      let selectedValue = currentValue;

      if (event.key === "ArrowLeft") {
        selectedValue = Math.max(currentValue - step, min);
      } else if (event.key === "ArrowRight") {
        selectedValue = Math.min(currentValue + step, max);
      }

      setCurrentValue(selectedValue);
      onChange(selectedValue);
    }
  };

  // Touch events
  const onTouchStart = (event) => {
    setIsDragging(true);
    moveSliderPosition(event);
  };

  const onTouchMove = useCallback(
    (event) => {
      moveSliderPosition(event);
    },
    [moveSliderPosition]
  );

  const onTouchEnd = useCallback(() => {
    onChange(currentValue);
    setIsDragging(false);
  }, [currentValue, onChange]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
      window.addEventListener("touchmove", onTouchMove, {
        passive: true,
      });
      window.addEventListener("touchend", onTouchEnd);
    }

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
    };
  }, [isDragging, onMouseMove, onMouseUp, onTouchEnd, onTouchMove]);

  return (
    <Slider
      min={min}
      max={max}
      width={width}
      withLabel={withLabel}
      currentValue={currentValue}
      isDragging={isDragging}
      onMouseDown={(e) => (!disabled ? onMouseDown(e) : null)}
      onTouchStart={(e) => (!disabled ? onTouchStart(e) : null)}
      onKeyDown={(e) => (!disabled ? onKeyDown(e) : null)}
      sliderRef={sliderRef}
      disabled={disabled}
    />
  );
}

function Slider(props) {
  const {
    min,
    max,
    width,
    withLabel,
    currentValue,
    isDragging,
    onMouseDown,
    onTouchStart,
    onKeyDown,
    sliderRef,
    disabled,
  } = props;

  return (
    <div
      ref={sliderRef}
      role="slider"
      tabIndex={0}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={currentValue}
      aria-label="Value control slider"
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onKeyDown={onKeyDown}
      style={{
        width: width ? `${width}px` : "100%",
      }}
      className={clsx("relative h-4 rounded-4 bg-grey-700", {
        "cursor-not-allowed": disabled,
      })}
      disabled={disabled}
    >
      <div
        className="absolute left-0 h-full rounded-4 bg-grey-700"
        style={{
          width: `${((currentValue - min) / (max - min)) * 100}%`,
        }}
      >
        <span
          className={clsx(
            "top-50% absolute flex h-24 w-40 -translate-y-1/2 justify-center rounded-40 border-0 bg-primary font-mazzardBold text-3xs ring-0",
            {
              "right-0": currentValue === 100,
              "right-[calc(-1*80px/2)]": currentValue === 0,
              "right-[calc(-1*40px/2)]":
                currentValue !== 100 && currentValue !== 0,
              "cursor-grabbing": isDragging && !disabled,
              "cursor-grab": !isDragging && !disabled,
              "cursor-not-allowed": disabled,
            }
          )}
        >
          <span
            className={clsx(
              "absolute left-1/2 top-1/2 z-[1] flex -translate-x-1/2 -translate-y-1/2 select-none items-center justify-center text-white",
              {
                "sr-only": !withLabel,
              }
            )}
          >
            {currentValue}%
          </span>
        </span>
      </div>
    </div>
  );
}

export default RangeSlider;
