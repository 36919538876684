import clsx from "clsx";

import HtmlFragment from "./html-fragment";

import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useHedgingContext } from "@/context/betaContext";
import config from "@/config";
import Image from "next/image";
import get from "lodash.get";
import { useWindowSize } from "react-use";

const BASE_CLASS = "unsubscribe";

function Unsubscribe(props) {
  const { heading, desktopImage, description, footnote, bgMobile, bgDesktop } =
    props;

  const desktop = get(useWindowSize(), "width") > 1024 ? true : false;
  const router = useRouter();
  const { user, isAuthenticated } = useAuth0();

  const { handleAllPortfolio } = useHedgingContext();

  const [suceess, setSuccess] = useState("");

  const [isLoadingPortfolio, setIsLoadingPortfolio] = useState(true);

  const localIdToken = localStorage.getItem("localIdToken");

  const handleBackToHome = async () => {
    router.push("home");

    // await router.push("home");

    if (isAuthenticated) {
      const path2 = `${config?.baseUrl}/api/getallportfolio?oktaid=${user?.sub}`;
      try {
        const portfolioResponse = await fetch(path2, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            oktatoken: localIdToken,
          },
        });

        const portfolioData = await portfolioResponse.json();
        handleAllPortfolio((prev) =>
          Array.isArray(portfolioData) ? portfolioData : prev
        );
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      } finally {
        setIsLoadingPortfolio(false);
      }
    }

    // window.location.reload();
  };

  const handleUnsubscribe = async () => {
    const url = `${config?.baseUrl}/api/Unsubscribe`;
    const data = {
      OKTA_ID: user?.sub,
      REASON: "Unsubscribe",
    };
    const localIdToken = localStorage.getItem("localIdToken");

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        oktatoken: localIdToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Unauthorized");
        }
        return response.json();
      })
      .then((data) => {
        setSuccess(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const updatedTemplate = description?.replace("{{email}}", user?.email);

  return props ? (
    <div
      style={{
        backgroundImage: desktop
          ? `url(${bgDesktop.url})`
          : `url(${bgMobile.url})`,
      }}
      className={clsx(
        BASE_CLASS,
        "relative mt-4 bg-contain bg-top bg-no-repeat py-[80px]"
      )}
    >
      <div className="container lg:px-[280px]">
        <div className="flex items-center justify-center">
          <Image
            className={clsx("")}
            src={desktopImage.url}
            alt={desktopImage.alt}
            width={220}
            height={100}
          />
        </div>
        <div className="pt-[82px] text-center  text-dark">
          <div className="pt-16 text-7xl">
            <HtmlFragment>{heading}</HtmlFragment>
          </div>
          <div className="pb-24 pt-24 text-md">
            <HtmlFragment>{updatedTemplate}</HtmlFragment>
          </div>
          <div className="flex items-center justify-center gap-[24px] border-b border-secondary pb-32">
            <button
              className="cta btn btn-primary text-center"
              onClick={handleUnsubscribe}
            >
              Unsubscribe
            </button>
            <button
              className="cta btn btn-secondary text-center"
              onClick={handleBackToHome}
            >
              Back to Home
            </button>
          </div>
          {suceess?.error === false ||
          suceess === "Suceesfully unsubscribed." ? (
            <div className="sucesss mt-16 inline-block bg-green px-24 py-16 text-sm text-white">
              Email alerts across all your portfolios have now been disabled.
              You can enable alerts by visiting the Settings tab within your
              individual portfolios.
            </div>
          ) : suceess?.error === true ? (
            <div className="sucesssError mt-16 inline-block px-24 py-16 text-sm text-red">
              We are currently unable to update your Email Alerts preferences,
              please try again later.
            </div>
          ) : null}
          <div className="pt-48 text-2xs">
            <HtmlFragment>{footnote}</HtmlFragment>
          </div>
        </div>
      </div>
      {/* Footer div positioned at the bottom */}
      <div className="absolute bottom-0 left-0 h-16 w-full bg-secondary"></div>
    </div>
  ) : null;
}

export default Unsubscribe;
