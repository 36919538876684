import axios from "axios";
import isEmpty from "lodash.isempty";

import config from "@/config";
import { throwError } from "./text";

const apiUrl = `${config?.baseUrl}`;

export async function getIdToken() {
  const localIdToken = localStorage.getItem("localIdToken");

  return Promise.resolve(localIdToken);
}

export const apiGetWithoutTokenRequest = async (endpoint) => {
  try {
    const response = await axios.get(apiUrl + endpoint, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("apiGetWithoutTokenRequest response : ", response);

    if (response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    }

    if (!isEmpty(response) && response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    if (!isEmpty(error) && error.response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    } else {
      throwError(error);
    }
  }
};

export const apiPostWithoutTokenRequest = async (endpoint, body) => {
  try {
    const response = await axios.post(endpoint, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("apiPostWithoutTokenRequest response : ", response);

    if (response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    }

    if (!isEmpty(response) && response.status === 200) {
      return response?.data;
    }
  } catch (error) {
    if (!isEmpty(error) && error.response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    } else {
      throwError(error);
    }
  }
};

export const apiGetRequest = async (endpoint) => {
  try {
    const localIdToken = await getIdToken();

    if (localIdToken) {
      const response = await axios.get(apiUrl + endpoint, {
        headers: {
          "Content-Type": "application/json",
          oktatoken: localIdToken,
        },
      });

      console.log("apiGetRequest response : ", response);

      if (response.status === 401) {
        return {
          data: null,
          status: response.status,
          error: response.error,
        };
      }

      if (!isEmpty(response) && response.status === 200) {
        return response?.data;
      }
    }
  } catch (error) {
    if (!isEmpty(error) && error.response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    } else {
      throwError(error);
    }
  }
};

export const apiPostRequest = async (endpoint, body) => {
  try {
    const localIdToken = await getIdToken();

    if (localIdToken) {
      const response = await axios.post(apiUrl + endpoint, body, {
        headers: {
          "Content-Type": "application/json",
          oktatoken: localIdToken,
        },
      });

      console.log("apiPostRequest response : ", response);

      if (response.status === 401) {
        return {
          data: null,
          status: response.status,
          error: response.error,
        };
      }

      if (!isEmpty(response) && response.status === 200) {
        return {
          data: response.data,
          statusCode: response.status,
          errors: null,
        };
      }
    }
  } catch (error) {
    if (!isEmpty(error) && error.response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    } else {
      throwError(error);
    }

    if (error.message) {
      throw new Error(error?.message?.toString());
    } else {
      throw new Error(error?.response?.data?.error?.toString());
    }
  }
};

export const apiPutRequest = async (endpoint, body) => {
  try {
    const localIdToken = await getIdToken();

    if (localIdToken) {
      const response = await axios.put(apiUrl + endpoint, body, {
        headers: {
          "Content-Type": "application/json",
          oktatoken: localIdToken,
        },
      });

      console.log("apiPutRequest response : ", response);

      if (response.status === 401) {
        return {
          data: null,
          status: response.status,
          error: response.error,
        };
      }

      if (!isEmpty(response) && response.status === 200) {
        return {
          data: response.data,
          statusCode: response.status,
          errors: null,
        };
      }
    }
  } catch (error) {
    if (!isEmpty(error) && error.response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    } else {
      throwError(error);
    }

    if (error.message) {
      throw new Error(error?.message?.toString());
    } else {
      throw new Error(error?.response?.data?.error?.toString());
    }
  }
};

export const apiDeleteRequest = async (endpoint) => {
  try {
    const localIdToken = await getIdToken();

    if (localIdToken) {
      const response = await axios.delete(apiUrl + endpoint, {
        headers: {
          "Content-Type": "application/json",
          oktatoken: localIdToken, // Ensure this is the actual token
        },
      });

      console.log("apiDeleteRequest response: ", response);

      if (response.status === 401) {
        return {
          data: null,
          status: response.status,
          error: response.error,
        };
      }

      if (!isEmpty(response) && response.status === 200) {
        return response?.data;
      }
    }
  } catch (error) {
    if (!isEmpty(error) && error.response.status === 401) {
      return {
        data: null,
        status: response.status,
        error: response.error,
      };
    } else {
      throwError(error);
    }

    if (error.message) {
      throw new Error(error?.message?.toString());
    } else {
      throw new Error(error?.response?.data?.error?.toString());
    }
  }
};
