import { lazy, Suspense } from "react";

const CodeSyntaxHighlighter = lazy(() => import("./code-syntax-highlighter"));

function CodeBlock({ code, language = "javascript" }) {
  return (
    <div className="w-full">
      <pre className="text-body-xxs block overflow-x-auto whitespace-pre-wrap break-normal rounded-4 bg-grey-200 p-8 text-inherit">
        <code className="break-normal font-mazzard text-xs text-inherit">
          <Suspense fallback={<Loading />}>
            <CodeSyntaxHighlighter
              code={JSON.stringify(code, null, 2)}
              language={language}
            />
          </Suspense>
        </code>
      </pre>
    </div>
  );
}

function Loading() {
  return <div>Loading Data...</div>;
}

export default CodeBlock;
