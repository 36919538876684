import React, { useEffect, useMemo, useRef, useState } from "react";
import { mutate } from "swr";
import { useHedgingContext } from "@/context/betaContext";
import { useAuth0 } from "@auth0/auth0-react";

import isEmpty from "lodash.isempty";

import { apiGetWithoutTokenRequest } from "@/lib/api";
import { apiKeys } from "@/lib/regex";

import { HtmlFragment as HF, Icon } from "./*";

import { formatDecimalNumber } from "../lib/text";
import clsx from "clsx";

function EditPortfolio(props) {
  const { user } = useAuth0();

  const {
    handleBetaValue,
    isSelectIndex,
    isSelectedBeta,
    handleIsTotalPortfolio,
    isDropChange,
    isSelectMultiple,
    isRequiredInvestment,
    handleIsDropBeta,
    handleIsTableData,
    tableData,
    getHedgeData,
    handleIsAlert,
    handleGetHedgeData,
    isSelectPortfolio,
    handleEditTableData,
    handleEditDataEnable,
    editTableData,
    editSortData,
    editDataEnable,
    editHolding,
    tickerCheck,
    handleTickerCheck,
    handleBlankValues,
    handleEditDeleteValue,
    editDeleteValue,
    handleTabClicked,
    tabClicked,
    handleTableIndex,
    tableIndex,
  } = useHedgingContext();

  const intialData = {
    ticker: "",
    ticker_name: "",
    market_close_value: "",
    weight: "",
    UNITS: "",
    value: "",
  };

  let [data, setData] = useState([intialData]);
  let [searchData, setSearchData] = useState([]);
  let [inputValue, setInputValue] = useState("");
  let [hideValue, setHideValue] = useState(false);
  let [currDropdownIndex, setCurrDropdownIndex] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [rowCount, setRowCount] = useState(1);
  const [rowDataCount, setRowDataCount] = useState(2);
  const [loading, setLoading] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedMultiple, setSelectedMultiple] = useState("");
  const [requiredInvestment, setRequiredInvestment] = useState("");
  const [getTickerList, setGetTickerList] = useState();
  const [tickerValue, setTickerValue] = useState(false);

  const obj = {
    TICKER: "",
    TICKER_NAME: "",
    PRICE: "",
    WEIGHT: "",
    UNITS: "",
    VALUE: "",
  };

  const uniquePortfolioData = [
    ...new Map(
      getHedgeData?.holdings?.map((item) => [item.TICKER, item])
    ).values(),
  ];

  const updateData = uniquePortfolioData?.map((cv) => {
    return {
      ...cv,
      PRICE: cv.CURRENT_PRICE,
      VALUE: parseFloat(cv.VALUE).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      UNITS: parseFloat(cv.UNITS).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      UNITS_POST: cv.UNITS.toString(),
    };
  });

  const [updatePortfolioData, setUpdatePortfolioData] = useState(
    !isEmpty(getHedgeData?.holdings) ? [...updateData, obj] : [obj]
  );

  useEffect(() => {
    !isDropChange &&
      setSelectedIndex(!!isSelectIndex ? isSelectIndex : "S&P 500");
    !isDropChange &&
      setSelectedMultiple(!!isSelectMultiple ? isSelectMultiple : "–1x");
    !isDropChange && setRequiredInvestment(isRequiredInvestment);
  }, [
    isDropChange,
    isRequiredInvestment,
    isSelectIndex,
    isSelectMultiple,
    selectedIndex,
    selectedMultiple,
  ]);

  useEffect(() => {
    handleIsTableData(data);
  }, [data, handleIsTableData]);

  useEffect(() => {
    if (!isEmpty(editSortData) && !editDataEnable && editDeleteValue) {
      handleEditTableData(editSortData);
    } else {
      handleEditTableData(updatePortfolioData);
    }
  }, [
    editDataEnable,
    editDeleteValue,
    editSortData,
    handleEditTableData,
    updatePortfolioData,
  ]);

  const dropdownRef = useRef(null);

  const replaceObjectUsingSlice = (array, newObject, index) => {
    const data = [
      ...array.slice(0, index),
      newObject,
      ...array.slice(index + 1),
    ];
    return index === array.length - 1 ? [...data, obj] : [...data];
  };

  const handleChangeRow = async (index, column, dataVal) => {
    const value = dataVal;

    setTickerValue(true);
    setLoading(true);
    setHideValue(true);
    handleEditDataEnable(true);

    const tickerData = updatePortfolioData?.some(
      (val) => val.TICKER === value.toUpperCase()
    );

    if (tickerData) {
      handleIsAlert({
        open: true,
        message: `${value} Ticker is already available`,
        error: true,
      });
    }

    if (updatePortfolioData.length >= 21) {
      handleIsAlert({
        open: true,
        message: "You have hit the limit.",
        error: false,
      });
    }

    if (value && !tickerData && updatePortfolioData.length < 21) {
      const tickerList = await mutate(apiKeys["hedgeTickerListKey"], () =>
        apiGetWithoutTokenRequest(`api/getticker?ticker=${value}`)
      );

      setLoading(false);
      setGetTickerList(tickerList);

      const newObj = {
        OKTA_ID: user?.sub,
        PORTFOLIO_ID: isSelectPortfolio,
        WEIGHT: 0,
        UNITS: 0,
        VALUE: 0,
        TICKER: tickerList?.ticker,
        TICKER_NAME: tickerList?.ticker_name,
        PRICE: tickerList?.market_close_value,
        beta_ndx_indx: tickerList?.beta_ndx_indx,
        beta_dja_indx: tickerList?.beta_dja_indx,
        beta_gspc_indx: tickerList?.beta_gspc_indx,
        beta_iwm: tickerList?.beta_iwm,
        beta_eem: tickerList?.beta_eem,
        beta_efa: tickerList?.beta_efa,
        beta_mid_indx: tickerList?.beta_mid_indx,
        beta_afty: tickerList?.beta_afty,
      };

      // Replacing the object
      const updatedArray = replaceObjectUsingSlice(
        updatePortfolioData,
        newObj,
        index
      );

      setUpdatePortfolioData(updatedArray);

      // Hide skeleton loader once data is fetched
      if (!isEmpty(tickerList)) {
        if (!!tickerList.ticker_name && tickerList?.beta_gspc_indx > 0) {
          const currentIndex = index;

          if (currentIndex < tableData.length - 1) {
            const temData = [...tableData];
            temData[currentIndex] = tickerList;

            handleGetHedgeData(temData);
            handleEditTableData(temData);
          } else {
            handleEditTableData([
              ...tableData.slice(0, -1),
              { ...tickerList },
              { ...intialData },
            ]);
          }
        }
      } else {
        handleIsAlert({
          open: true,
          message: "No ticker available",
          error: true,
        });
      }
    }
  };

  const handleUnitValueOnBlur = (e, index, column) => {
    const input = e.target;
    let value = input.value.trim();

    // Clean the value to allow only numbers, dots, and commas
    const cleanedValue = value.replace(/[^0-9.,]/g, "");

    // Convert cleaned value to a number and format it
    let number = parseFloat(cleanedValue.replace(/,/g, "")) || 0;
    let formattedValue = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Update the input field value
    input.value = formattedValue;

    // Update table data
    const data = [...editTableData];
    data[index][column] = formattedValue;

    if (column === "UNITS") {
      const marketCloseValue = parseFloat(data[index].PRICE);
      if (marketCloseValue && number) {
        const calculatedValue = (marketCloseValue * number).toFixed(2);
        data[index].VALUE = Number(calculatedValue).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        data[index].VALUE = "";
        data[index].UNITS = "";
      }
    } else if (column === "VALUE") {
      const marketCloseValue = parseFloat(data[index].PRICE);
      if (marketCloseValue && number) {
        const calculatedUnits = (number / marketCloseValue).toFixed(2);
        data[index].UNITS = Number(calculatedUnits).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        data[index].UNITS = "";
        data[index].VALUE = "";
      }
    }

    // Update table data state
    handleEditTableData(data);
  };

  const handleChangeUnitValue = (e, index, column) => {
    const input = e.target;
    const originalCursorPosition = input.selectionStart;
    const originalLength = input.value.length;

    const value = input.value.trim();

    // Clean the input value to allow only numbers, dots, and commas
    const cleanedValue = value.replace(/[^0-9.,]/g, "");

    // Create a copy of the current data
    const newData = [...editTableData];

    // Update the specific field in the current row with the cleaned value
    newData[index][column] = cleanedValue;

    // Process UNITS column
    if (column === "UNITS") {
      const PRICE = parseFloat(newData[index].PRICE);
      const unit = cleanedValue
        ? parseFloat(cleanedValue.replace(/,/g, ""))
        : NaN;

      if (!isNaN(PRICE) && !isNaN(unit) && unit >= 0) {
        const calculatedValue = (PRICE * unit).toFixed(2);
        const calculatedPostValue = PRICE * unit;
        newData[index].VALUE =
          unit > 0 ? Number(calculatedValue).toLocaleString() : "";
        newData[index].VALUE_POST = unit > 0 ? Number(calculatedPostValue) : "";
      } else {
        newData[index].VALUE = "";
        newData[index].VALUE_POST = "";
      }

      // Format UNITS with commas for display
      newData[index].UNITS = cleanedValue || "";
      newData[index].UNITS_POST = unit || "";
      // Process VALUE column
    } else if (column === "VALUE") {
      const PRICE = parseFloat(newData[index].PRICE);
      const parsedValue = cleanedValue
        ? parseFloat(cleanedValue.replace(/,/g, ""))
        : NaN;

      if (!isNaN(PRICE) && !isNaN(parsedValue) && parsedValue >= 0) {
        const UNITS = PRICE > 0 ? (parsedValue / PRICE).toFixed(2) : "";
        const estPostUnit = PRICE > 0 ? parsedValue / PRICE : "";

        newData[index].UNITS = UNITS ? Number(UNITS).toLocaleString() : "";
        newData[index].UNITS_POST = estPostUnit ? estPostUnit : "";
        newData[index].VALUE_POST = parsedValue;
      } else {
        newData[index].UNITS = "";
        newData[index].UNITS_POST = "";
      }

      // Format VALUE with commas for display
      newData[index].VALUE = cleanedValue;
    }

    // Recalculate the weights for all rows based on updated values
    const totalSum = newData?.reduce((acc, cv) => {
      const VALUE = parseFloat(cv.VALUE?.replace(/,/g, "")) || 0;
      return acc + VALUE;
    }, 0);

    newData.forEach((row) => {
      const VALUE = parseFloat(row.VALUE?.replace(/,/g, "")) || 0;
      row.WEIGHT =
        totalSum > 0 && VALUE > 0 ? ((VALUE / totalSum) * 100).toFixed(2) : "";
    });

    // Update the state with the new data
    handleEditTableData(newData);

    // Adjust cursor position after state update
    setTimeout(() => {
      const newLength = input.value.length;
      const diff = newLength - originalLength;
      const newCursorPosition = originalCursorPosition + diff;

      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const totalPortfolio = updatePortfolioData?.reduce((acc, cv) => {
    return acc + parseFloat(cv.value || 0);
  }, 0);

  function useDebounce(callback, delay) {
    const [timeoutId, setTimeoutId] = useState(null);

    function debounceFunction(...args) {
      clearTimeout(timeoutId);
      const id = setTimeout(() => {
        callback(...args);
      }, delay);
      setTimeoutId(id);
    }

    return debounceFunction;
  }

  // Custom hook for debouncing the input change handler
  const handleInputChangeDebounced = useDebounce(async (value) => {
    const tickerList = await mutate(
      apiKeys["hedgeTickerListKey"],
      () =>
        apiGetWithoutTokenRequest(
          `api/searchticker?ticker=${value}&pageno=1&rows=20`,
          true
        ),
      { revalidate: true }
    );

    if (!isEmpty(tickerList) && Array.isArray(tickerList)) {
      setSearchData(tickerList);
    } else {
      handleIsAlert({
        open: true,
        message: "Ticker not found",
        error: true,
      });
    }
  }, 1000);

  const handleSearchRow = (index, column, e) => {
    const value = e.target.value;
    setTickerValue(false);
    setInputValue(value);
    setHideValue(false);
    setCurrDropdownIndex(index);
    handleInputChangeDebounced(value);
    setIsOpen(true);
    handleTabClicked(false);
    handleTableIndex(index);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataCheckEdit = editHolding
    ? tickerCheck && filterDataEdit(editTableData, isSelectedBeta)
    : [];

  useEffect(() => {
    if (dataCheckEdit?.length === 0) {
      handleTickerCheck(false);
    }
  }, [dataCheckEdit, handleTickerCheck]);

  // for delete any row
  const handleDeleteRow = (ticker, index, ticker_name) => {
    const newData = [...updatePortfolioData];

    // If ticker is not empty, remove the item at the specified index
    if (!isEmpty(ticker)) {
      newData.splice(index, 1);
    }

    if (dataCheckEdit.length > 0) {
      dataCheckEdit?.filter((str) => str !== ticker_name);
    }

    // Calculate the total portfolio value
    const totalPortfolio = newData.reduce((acc, cv) => {
      const value = parseFloat(
        cv.VALUE ? String(cv.VALUE).replace(/,/g, "") : 0
      );
      return acc + value;
    }, 0);

    // Update the data and calculate weights
    const formatData = newData.map((item) => {
      const value = parseFloat(
        item.VALUE ? String(item.VALUE).replace(/,/g, "") : 0
      );
      const weight =
        totalPortfolio > 0 ? ((value / totalPortfolio) * 100).toFixed(2) : "";

      return {
        ...item,
        WEIGHT: weight > 1 ? formatDecimalNumber(weight) : null, // Only update weight if it’s calculable
        VALUE: value > 1 ? formatDecimalNumber(value) : null, // Format value if > 1
      };
    });

    setUpdatePortfolioData(formatData);
    handleEditTableData(formatData);
    handleBlankValues(false);
    handleEditDeleteValue(false);
  };

  // This is for Edit Flow Beta
  const totalBetaValue = tableData?.reduce((acc, curr) => {
    if (curr.hasOwnProperty(`beta_${isSelectedBeta?.toLowerCase()}`)) {
      const value = parseFloat(curr.value || 0);
      const selectedBeta = parseFloat(
        curr[`beta_${isSelectedBeta?.toLowerCase()}`] || 0
      );
      return acc + value * selectedBeta;
    } else {
      // Handle the case where isSelectedBeta key is not found in the current object
      const value = parseFloat(curr.value || 0);
      return acc + value * parseFloat(curr.beta_gspc_indx || 0);
    }
  }, 0);

  const betaValues = useMemo(() => {
    const obj = {
      afty: "afty",
      dja_indx: "dja_indx",
      eem: "eem",
      efa: "efa",
      gspc_indx: "gspc_indx",
      iwm: "iwm",
      mid_indx: "mid_indx",
      ndx_indx: "ndx_indx",
    };
    const values = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const totalBetaValue = tableData?.reduce((acc, curr) => {
          if (curr.hasOwnProperty(`beta_${key}`)) {
            const value = parseFloat(curr.value || 0);
            const selectedBeta = parseFloat(curr[`beta_${key}`] || 0);
            return acc + value * selectedBeta;
          } else {
            const value = parseFloat(curr.value || 0);
            return acc + value * parseFloat(curr[`beta_${key}`] || 0);
          }
        }, 0);
        values.push({ name: key, totalBetaValue: totalBetaValue });
      }
    }
    return values;
  }, [tableData]);

  useEffect(() => {
    handleBetaValue(totalBetaValue);
    handleIsDropBeta(betaValues);
    handleIsTotalPortfolio(totalPortfolio);
  }, [
    betaValues,
    handleBetaValue,
    handleIsDropBeta,
    handleIsTotalPortfolio,
    totalBetaValue,
    totalPortfolio,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleUpdateRow = async () => {
    setRowCount(2);
    setRowDataCount(rowDataCount + 1);

    const updateTickerList = await mutate(
      apiKeys["hedgeTickerListKey"],
      () =>
        apiGetWithoutTokenRequest(
          `api/searchticker?ticker=2&pageno=${rowDataCount}&rows=20`
        ),
      { revalidate: true }
    );

    if (!isEmpty(updateTickerList) && Array.isArray(updateTickerList)) {
      setSearchData([...searchData, ...updateTickerList]);
    }
  };

  const tdRef = useRef(null);

  const inputRefs = useRef(editTableData?.map(() => React.createRef()));
  // Ensure inputRefs are initialized correctly based on tableData length
  useEffect(() => {
    inputRefs.current = editTableData?.map(
      (_, index) => inputRefs?.current?.[index] || React.createRef()
    );
  }, [editTableData]);

  // Effect to focus the input when tabClicked is true
  const check = editTableData
    ?.filter((val) => val.TICKER !== "")
    .some((val) => val.UNITS === 0);

  useEffect(() => {
    if (check && inputRefs?.current[tableIndex]) {
      inputRefs?.current[tableIndex]?.current?.focus();
      inputRefs?.current[tableIndex]?.current?.select();
    }
  }, [check, tableIndex]);

  return props
    ? editTableData?.map((row, index) => {
        return (
          <tr key={index} className="mt-4">
            <td
              ref={tdRef}
              className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100"
            >
              <div className="relative">
                <div className="builder-tabs__search">
                  <input
                    type="text"
                    placeholder="Enter Ticker"
                    data-id={index}
                    data-ticker={row.ticker ?? ""}
                    value={
                      !tickerValue && currDropdownIndex === index
                        ? inputValue
                        : row.TICKER
                    }
                    disabled={
                      updatePortfolioData.length > 1 &&
                      !updatePortfolioData[updatePortfolioData.length - 2]
                        ?.UNITS
                    }
                    className="size-full bg-transparent p-4 placeholder:italic focus:outline-none"
                    onChange={(e) => {
                      handleSearchRow(index, "TICKER", e);
                    }}
                    onKeyDown={(e) => {
                      // Check if Enter key is pressed
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent any default behavior like form submission
                        handleChangeRow(
                          editTableData.length - 1,
                          "ticker",
                          searchData?.[0]?.TICKER
                        );
                      }
                    }}
                    aria-label="Search"
                  />
                </div>
                {searchData.length != 0 &&
                  !hideValue &&
                  currDropdownIndex === index &&
                  isOpen && (
                    <ul
                      role="listbox"
                      ref={dropdownRef}
                      className="top-106% absolute left-0 right-0 z-50 h-[160px] w-[200%] overflow-auto 
                        border border-b-0 border-grey-500 bg-grey-200"
                      onFocus={() => {
                        handleTabClicked(true);
                      }}
                    >
                      {searchData?.map((value, key) => {
                        return (
                          <li
                            key={key}
                            role="listitem"
                            className={`${
                              !tabClicked ? "border-black first:border" : null
                            }`}
                          >
                            <button
                              className="text-16 w-full cursor-pointer border-b border-grey-500 p-4 text-left"
                              onClick={(e) => {
                                handleChangeRow(index, "ticker", value.TICKER);
                              }}
                            >
                              <span className="font-mazzardSemiBold text-secondary">
                                {value.TICKER}
                              </span>
                              - {value.TICKER_NAME}
                            </button>
                          </li>
                        );
                      })}
                      {searchData.length > 19 ? (
                        <li role="listitem">
                          <button
                            onClick={() => handleUpdateRow()}
                            className="my-8 ml-8 inline-block cursor-pointer rounded-xl border border-b border-grey-500 bg-grey-100 px-12 py-4 text-[14px] font-semibold text-secondary"
                            aria-label="View more"
                          >
                            View More
                          </button>
                        </li>
                      ) : null}
                    </ul>
                  )}
              </div>
            </td>
            <td
              className={clsx(
                "w-[35%] border border-l-0 border-r-0 border-t-0  p-4",
                dataCheckEdit &&
                  dataCheckEdit?.includes(row.TICKER_NAME) &&
                  tickerCheck
                  ? "border-b-red"
                  : "border-b-grey-500"
              )}
            >
              {row.TICKER_NAME}
            </td>
            <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 text-right">
              {row.market_close_value?.toString().length < 1
                ? `${row.market_close_value}`
                : `${!!row.PRICE ? `$${Number(row.PRICE).toFixed(2)}` : ""} `}
            </td>
            <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-grey-500 p-4 text-right">
              {!!row?.WEIGHT ? `${row.WEIGHT}%` : ""}
            </td>
            <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100">
              <input
                ref={inputRefs?.current?.[index]}
                className="size-full bg-transparent p-4 text-right focus:outline-none"
                value={!!row.UNITS ? row.UNITS : ""}
                type="text"
                disabled={!row.TICKER ? true : false}
                onChange={(e) => {
                  if (e.target.value.length <= 8) {
                    handleChangeUnitValue(e, index, "UNITS");
                  }
                }}
                onBlur={(e) => handleUnitValueOnBlur(e, index, "UNITS")}
                aria-label="Units"
              />
            </td>
            <td className="w-[12%] border border-l-0 border-r-0 border-t-0 border-b-black bg-gray-100">
              <input
                className="size-full appearance-none bg-transparent p-4 text-right focus:outline-none"
                // value={!!row.VALUE ? `$${intlFormat(row.VALUE).showVal}` : ""}
                value={!!row.VALUE ? `$${row.VALUE}` : ""}
                type="text"
                disabled={!row.TICKER ? true : false}
                onChange={(e) => handleChangeUnitValue(e, index, "VALUE")}
                onBlur={(e) => handleUnitValueOnBlur(e, index, "VALUE")}
                aria-label="Value"
              />
            </td>
            <td className="w-[4%] text-center">
              <button
                onClick={() =>
                  handleDeleteRow(row.TICKER, index, row.ticker_name)
                }
                aria-label="Tuple remove"
              >
                <Icon className="text-14" name="tuple-remove-purple" />
              </button>
            </td>
          </tr>
        );
      })
    : null;
}

export const filterDataEdit = (data, isSelectedBeta) => {
  const selectBeta = isSelectedBeta?.toLowerCase().split(".").join("_");
  return data
    ?.filter((item) => {
      const betaMap = {
        beta_ndx_indx: item.beta_ndx_indx,
        beta_dja_indx: item.beta_dja_indx,
        beta_gspc_indx: item.beta_gspc_indx,
        beta_iwm: item.beta_iwm,
        beta_eem: item.beta_eem,
        beta_efa: item.beta_efa,
        beta_mid_indx: item.beta_mid_indx,
        beta_afty: item.beta_afty,
      };

      // Find the beta value that matches the isSelectedBeta
      const selectedBetaValue = betaMap[`beta_${selectBeta}`];
      return (
        item.TICKER_NAME !== "" &&
        (selectedBetaValue === 0 || selectedBetaValue === undefined)
      );
    })
    ?.map((item) => item.TICKER_NAME);
};

export default EditPortfolio;
