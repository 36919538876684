import { useState } from "react";
import clsx from "clsx";

import { Icon, HtmlFragment as HF } from "./*";
import { useHedgingContext } from "@/context/betaContext";

function Tooltip(props) {
  const {
    children,
    info,
    tooltipLeft,
    color,
    size,
    fontColor,
    tabToggleData,
    tabToggle,
    tabCount,
  } = props;
  const [showInfo, setShowInfo] = useState(false);
  const black = color === "black";
  const { handleActive } = useHedgingContext();

  const handleShowInfo = () => {
    setShowInfo((prev) => !prev);
  };

  const handleClose = () => {
    setShowInfo(true);
  };

  return (
    <span className="flex gap-x-4">
      {children}
      {info ? (
        <span
          className="relative h-fit"
          tabIndex={0}
          onMouseEnter={handleShowInfo}
          onMouseLeave={handleShowInfo}
          // onBlur={handleShowInfo}
          // onFocus={handleShowInfo}
          // onClick={handleClose}
        >
          <Icon name={black ? "info-black" : "info"} size={size} />
          <div
            className={clsx(
              `${tooltipLeft ? "left-[-270px]" : "left-full top-1/2"}`,
              `${fontColor === "black" && "text-black"}`,
              "text-body-xs absolute z-[99] min-w-[280px] border border-grey-400 bg-white p-12",
              { block: showInfo, hidden: !showInfo }
            )}
          >
            <HF>{info}</HF>
            {tabToggle && (
              <button
                className="text-blue-200"
                onClick={() => handleActive(tabCount)}
              >
                {tabToggleData}
              </button>
            )}
          </div>
        </span>
      ) : null}
    </span>
  );
}

export default Tooltip;
