import { useHedgingContext } from "@/context/betaContext";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import Icon from "./icon";

function AlertBox(props) {
  const { isAlert, handleIsAlert } = useHedgingContext();
  const { open, message, error } = isAlert || {};
  let timer = useRef();

  const closeAlert = () => {
    const toast = document.querySelector(".toast");

    handleIsAlert();
    toast.classList.remove("active");
    clearTimeout(timer.current);
  };

  // hide alert
  useEffect(() => {
    if (isAlert?.open) {
      const toast = document.querySelector(".toast");

      toast.classList.add("active");

      timer.current = setTimeout(() => {
        toast.classList.remove("active");
        handleIsAlert();
      }, 5000);
    }
  }, [handleIsAlert, isAlert]);

  return (
    <div
      className={clsx(
        "toast z-[1201] min-w-[calc(100%-60px)] border-l-[12px] sm:min-w-[550px]",
        {
          active: open,
          "border-error": error,
          "border-secondary": !error,
        }
      )}
    >
      <button
        className="float-right rounded-xl border border-solid border-black"
        onClick={closeAlert}
        aria-label="Close alert"
      >
        <Icon
          name="close"
          className={clsx(
            "h-[32px] w-[32px] origin-center transform justify-self-end transition duration-200 ease-out"
          )}
        />
      </button>
      <div className="toast-content">
        <div className="message">
          {error ? (
            <span className="font-mazzardBold text-lg text-error">Error</span>
          ) : (
            <span className="font-mazzardBold text-lg text-secondary">
              Success
            </span>
          )}
          <span className="mt-16">{message}</span>
        </div>
      </div>
    </div>
  );
}

export default AlertBox;
