import React, { useEffect, useState, useCallback } from "react";
import { mutate } from "swr";
import clsx from "classnames";
import { useHedgingContext } from "@/context/betaContext";
import isEmpty from "lodash.isempty";
import { LineTitle } from "./*";
import HtmlFragment from "./html-fragment";

import { useAuth0 } from "@auth0/auth0-react";

import { useRouter } from "next/router";

import { apiPutRequest } from "@/lib/api";
import { apiKeys } from "@/lib/regex";

const BASE_CLASS = "notification";

function Notification(props) {
  const {
    name,
    titleInfo,
    selectOptions,
    emailNotificationTitle,
    emailNotificationInfo,
    notifyTabs,
    checkBoxContent,
  } = props;

  const router = useRouter();

  const { user } = useAuth0();

  const {
    handleHedgePercent,
    getAllPortfolio,
    updateDropLabel,
    handleIsAlert,
    hedgePercent,
    isSelectPortfolio,
    handleSelectNotification,
    selectNotification,
    settingPortfolioId,
    handleChangeNotificationTab,
    changeNotificationTab,
    handleCalendarSubitem,
    handleHedgePercentBelow,
    handleHedgePercentAbove,
    hedgePercentBelow,
    hedgePercentAbove,
    handleChangePercent,
    changePercent,
    totalHedgePercent,
    tabDataChange,
    handleCheckDropAbove,
    handleCheckDropBelow,
    checkDropBelow,
    checkDropAbove,
  } = useHedgingContext();

  const [selectedNotification, setSelectedNotification] = useState("No Alerts");
  const [selectedCalendarSubitem, setSelectedCalendarSubitem] =
    useState("No Alerts");
  const [filteredData, setFilteredData] = useState([]);
  const [updateSave, setUpdateSave] = useState(false);

  const getPortfolioId = localStorage.getItem("portfolio_Id");

  useEffect(() => {
    if (!getAllPortfolio) {
      return;
    }
    const selectedId = !!getPortfolioId
      ? Number(getPortfolioId)
      : isSelectPortfolio
      ? isSelectPortfolio
      : getAllPortfolio[0]?.ID;

    const filteredItem = getAllPortfolio.find((item) => item.ID === selectedId);

    setFilteredData(filteredItem);
  }, [getAllPortfolio, getPortfolioId, isSelectPortfolio]);

  // range states
  const [selectedValueAbove, setSelectedValueAbove] = useState("");
  const [selectedValueBelow, setSelectedValueBelow] = useState("");

  const [isBelowSet, setIsBelowSet] = useState(false);
  const [isAboveSet, setIsAboveSet] = useState(false);
  const [updatePercentage, setUpdatePercentage] = useState(false);

  // const [changePercent, setChangePercent] = useState(false);

  const sortedData = getAllPortfolio?.sort(
    (a, b) =>
      new Date(b.DATE_CREATED_UPDATED) - new Date(a.DATE_CREATED_UPDATED)
  );

  const checkId = !!getPortfolioId
    ? Number(getPortfolioId)
    : router?.query?.portfolio_id
    ? Number(router?.query?.portfolio_id)
    : sortedData?.[0]?.ID;

  const defaultPortfolioData = sortedData?.find((val) => val.ID === checkId);

  useEffect(() => {
    if (!!isSelectPortfolio) {
      // Always update the selected value when filteredData changes
      setSelectedValueAbove(
        `${
          changePercent ? hedgePercentAbove : defaultPortfolioData?.RANGE_ABOVE
        }%`
      );
      setSelectedValueBelow(
        `${
          changePercent ? hedgePercentBelow : defaultPortfolioData?.RANGE_BELOW
        }%`
      );
    }
  }, [
    changePercent,
    defaultPortfolioData?.RANGE_ABOVE,
    defaultPortfolioData?.RANGE_BELOW,
    filteredData,
    hedgePercentAbove,
    hedgePercentBelow,
    isSelectPortfolio,
  ]);

  useEffect(() => {
    if (filteredData) {
      // When filtered data changes, make sure to update selected notification and range
      setSelectedNotification(filteredData?.ALERTS || "No Alerts");
    }
  }, [filteredData]);

  useEffect(() => {
    if (notifyTabs?.length > 0 && !selectedNotification) {
      // Ensure default selection is set if there's no selected notification
      setSelectedNotification(notifyTabs[0].heading);
    }
  }, [notifyTabs, selectedNotification]);

  const setAlerts = getAllPortfolio?.find(
    (val) => val.ID === settingPortfolioId
  );

  // This is for Tabs
  useEffect(() => {
    handleSelectNotification(
      changeNotificationTab ? selectNotification : setAlerts?.ALERTS
    );
  }, [
    changeNotificationTab,
    handleSelectNotification,
    selectNotification,
    setAlerts?.ALERTS,
  ]);

  // useEffect for hedgePercentBelow
  useEffect(() => {
    handleHedgePercentBelow(
      changePercent ? hedgePercentBelow : defaultPortfolioData?.RANGE_BELOW
    );
    setIsBelowSet(true);
  }, [
    changePercent,
    hedgePercentBelow,
    defaultPortfolioData?.RANGE_BELOW,
    handleHedgePercentBelow,
    tabDataChange,
  ]);

  // recent commented (03/10)
  // useEffect(() => {
  //   isAuthenticated &&
  //     changePercent &&
  //     updateSave &&
  //     setSelectedCalendarSubitem(filteredData?.ALERTS);
  // }, [
  //   changePercent,
  //   filteredData?.ALERTS,
  //   isAuthenticated,
  //   tabDataChange,
  //   updateSave,
  // ]);

  // useEffect for hedgePercentAbove
  useEffect(() => {
    handleHedgePercentAbove(
      changePercent ? hedgePercentAbove : defaultPortfolioData?.RANGE_ABOVE
    );
    setIsAboveSet(true);
  }, [
    changePercent,
    hedgePercentAbove,
    defaultPortfolioData?.RANGE_ABOVE,
    handleHedgePercentAbove,
    tabDataChange,
  ]);

  // Ensure notification tab is set properly when changeNotificationTab changes
  useEffect(() => {
    handleCalendarSubitem(
      changeNotificationTab ? selectNotification : defaultPortfolioData?.ALERTS
    );
  }, [
    changeNotificationTab,
    selectNotification,
    defaultPortfolioData?.ALERTS,
    handleCalendarSubitem,
  ]);

  const defaultPortfolioData2 = getAllPortfolio?.find(
    (val) => val.ID === Number(router?.query?.portfolio_id)
  );

  useEffect(() => {
    setSelectedCalendarSubitem(
      changeNotificationTab ? selectedCalendarSubitem : defaultPortfolioData2
    );
  }, [changeNotificationTab, defaultPortfolioData2, selectedCalendarSubitem]);

  // range functions
  // const handleChangeAbove = (e) => {
  //   const value = e?.target.value?.replace("%", "");
  //   setSelectedValueAbove(e.target.value);
  //   handleHedgePercent((prev) => ({ ...prev, [type]: Number(value) }));
  //   // handleNotificationPercent(e, "below");
  // };

  // const handleChangeBelow = (e) => {
  //   const value = e?.target.value?.replace("%", "");
  //   setSelectedValueBelow(value);
  //   handleHedgePercent((prev) => ({ ...prev, [type]: Number(value) }));
  //   // handleNotificationPercent(e, "below");
  // };

  const handleDivClick = (value) => {
    setSelectedNotification("Calendar");
    setSelectedCalendarSubitem(value);
    handleSelectNotification(value);
    handleCalendarSubitem(value);
    handleChangeNotificationTab(true);

    // Optional: save or use the value outside the component
    // You can also dispatch an action or call a function here if needed
  };

  const handleSettings = async () => {
    // const settings = {
    //   selectedNotification,
    //   selectedCalendarSubitem,
    //   isCheckboxChecked,
    // };
    // Send `settings` to the server or perform any action needed

    setUpdateSave(true);

    const postData = {
      OKTA_ID: user?.sub,
      NAME: updateDropLabel,
      RANGE_ABOVE: selectedValueAbove?.replace("%", "") || hedgePercent?.above,

      RANGE_BELOW: selectedValueBelow?.replace("%", "") || hedgePercent?.below,
      ALERTS:
        typeof selectedCalendarSubitem === "object" &&
        selectedCalendarSubitem !== null
          ? selectedCalendarSubitem?.ALERTS
          : selectedCalendarSubitem,
      ID:
        router?.query?.portfolio_id === undefined
          ? isSelectPortfolio
          : Number(router?.query?.portfolio_id),
    };

    const {
      data: updatePortfolio,
      status: statusAddNewPortfolio,
      errors: errorsAddNewPortfolio,
    } = await mutate(
      apiKeys["hedgePortfolioKey"],
      () => apiPutRequest(`api/updateportfolio`, JSON.stringify(postData)),
      {
        revalidate: true,
      }
    );

    if (!isEmpty(updatePortfolio) && !updatePortfolio?.error) {
      handleIsAlert({
        open: true,
        message: "Setting Updated Successfully.",
        error: false,
      });
    }
  };

  const postData = useCallback(async () => {
    if (
      (!updatePercentage && checkDropAbove) ||
      (!updatePercentage && checkDropBelow)
    ) {
      const postData = {
        OKTA_ID: user?.sub,
        NAME: updateDropLabel,
        RANGE_ABOVE: hedgePercentAbove,
        RANGE_BELOW: hedgePercentBelow,
        ALERTS:
          typeof selectedCalendarSubitem === "object" &&
          selectedCalendarSubitem !== null
            ? selectedCalendarSubitem?.ALERTS
            : selectedCalendarSubitem,
        ID:
          router?.query?.portfolio_id === undefined
            ? isSelectPortfolio
            : Number(router?.query?.portfolio_id),
      };

      const {
        data: updatePortfolio,
        status: statusAddNewPortfolio,
        errors: errorsAddNewPortfolio,
      } = await mutate(
        apiKeys["hedgePortfolioKey"],
        () => apiPutRequest(`api/updateportfolio`, JSON.stringify(postData)),
        {
          revalidate: true,
        }
      );

      if (!isEmpty(updatePortfolio) && !updatePortfolio?.error) {
        setUpdatePercentage(true);
        handleCheckDropBelow(false);
        handleCheckDropAbove(false);
      }
    }
  }, [
    checkDropAbove,
    checkDropBelow,
    handleCheckDropAbove,
    handleCheckDropBelow,
    hedgePercentAbove,
    hedgePercentBelow,
    isSelectPortfolio,
    router?.query?.portfolio_id,
    selectedCalendarSubitem,
    updateDropLabel,
    updatePercentage,
    user?.sub,
  ]);

  useEffect(() => {
    if (isBelowSet && isAboveSet) {
      postData();
    }
  }, [isBelowSet, isAboveSet, postData]);

  const handleNotificationPercent = async (e, type) => {
    const value = e?.target.value?.replace("%", "");
    handleHedgePercent((prev) => ({ ...prev, [type]: Number(value) }));

    type === "below"
      ? handleHedgePercentBelow(Number(value))
      : handleHedgePercentAbove(Number(value));

    // setChangePercent(true);

    if (type === "below") {
      setSelectedValueBelow(e.target.value);
      handleHedgePercentBelow(Number(value));
    } else if (type === "above") {
      setSelectedValueAbove(e.target.value);
      handleHedgePercentAbove(Number(value));
    }

    handleChangePercent(true);
    if (type === "above") {
      handleCheckDropAbove(true);
    } else if (type === "below") {
      handleCheckDropBelow(true);
    }

    handleChangePercent(true);
    setUpdatePercentage(false);

    postData();
  };

  return !isEmpty(props) ? (
    <div className={clsx(BASE_CLASS, "mb-32 pt-16 lg:mb-48")}>
      <div className="container">
        <div className="my-16">{name ? <LineTitle text={name} /> : null}</div>
        {titleInfo && (
          <div className="text-body-sm w-full text-dark lg:w-[70%]">
            {titleInfo}
          </div>
        )}

        <div className="flex flex-row py-20">
          <div className="basis-1/6 py-8 font-semibold text-black">
            Target Exposure Range
          </div>
          <div className="basis-1/2">
            <div className="pb-16">
              <select
                name={selectOptions?.belowDropdown?.dropdownName}
                id={selectOptions?.belowDropdown?.dropdownName}
                className="mx-8 appearance-none bg-grey-200 bg-[url('../public/icons/dropdown-small.svg')] bg-[right_0.5rem_center] bg-no-repeat py-8 pl-8 pr-40 font-semibold"
                onChange={(e) => handleNotificationPercent(e, "below")}
                value={selectedValueBelow}
              >
                {selectOptions?.belowDropdown?.dropdownValues
                  ?.split(",")
                  ?.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
              <label
                htmlFor={selectOptions?.belowDropdown?.dropdownName}
                className="mr-8"
              >
                {selectOptions?.belowDropdown?.dropdownName} my target{" "}
                <span className="px-4 font-semibold text-black">
                  {/* {`${getHedgeData?.hedgeholding?.HEDGE_PERCENT}%`} */}
                  {totalHedgePercent ? totalHedgePercent : "50"}
                </span>
                hedged exposure.
              </label>
            </div>
            <div>
              <select
                name={selectOptions?.aboveDropdown?.dropdownName}
                id={selectOptions?.aboveDropdown?.dropdownName}
                className="mx-8 appearance-none bg-grey-200 bg-[url('../public/icons/dropdown-small.svg')] bg-[right_0.5rem_center] bg-no-repeat py-8 pl-8 pr-40 font-semibold"
                onChange={(e) => handleNotificationPercent(e, "above")}
                value={selectedValueAbove}
              >
                {selectOptions?.aboveDropdown?.dropdownValues
                  ?.split(",")
                  ?.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
              <label
                htmlFor={selectOptions?.aboveDropdown?.dropdownName}
                className="mr-8"
              >
                {selectOptions?.aboveDropdown?.dropdownName} my target{" "}
                <span className="px-4 font-semibold text-black">
                  {/* {Math.round(hedgePercentage)}% */}
                  {totalHedgePercent ? totalHedgePercent : "50"}
                </span>
                hedged exposure.
              </label>
            </div>
          </div>
        </div>

        <div className="my-16">
          {emailNotificationTitle ? (
            <LineTitle text={emailNotificationTitle} />
          ) : null}
        </div>
        {emailNotificationInfo && (
          <div className="text-body-sm w-full text-dark lg:w-[70%]">
            {emailNotificationInfo}
          </div>
        )}

        {notifyTabs && (
          <div className="grid grid-cols-1 gap-16 py-20 lg:grid-cols-4">
            {notifyTabs.map((tabs, tabIndex) => {
              const isChecked =
                (selectedNotification === "Calendar" &&
                  selectedCalendarSubitem === tabs.heading) ||
                (selectedNotification === "No Alerts" &&
                  tabs.heading === "No Alerts") ||
                selectedNotification === tabs.heading;

              return (
                <div
                  key={tabIndex}
                  className={clsx(
                    `${
                      isChecked
                        ? "border border-secondary"
                        : "border border-grey-300"
                    }`,
                    "cursor-pointer"
                  )}
                  onClick={() => handleDivClick(tabs.heading)}
                >
                  <div
                    className={clsx(
                      `${
                        isChecked ? "bg-secondary text-white" : "bg-grey-300"
                      }`,
                      "flex items-center px-8 py-12"
                    )}
                  >
                    <input
                      className="mx-8 accent-[#ffffff]"
                      type="radio"
                      id={tabs.tabIndex}
                      name="notification"
                      value={tabs.heading}
                      checked={isChecked}
                      readOnly
                    />
                    <span className="font-mazzardSemiBold text-sm">
                      {tabs.heading}
                    </span>
                  </div>
                  <div className="px-16 py-12">{tabs.subHeading}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className="flex items-center">
          {/* <input
            id="checked-checkbox"
            type="checkbox"
            value=""
            className="h-16 w-16 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
            checked={isCheckboxChecked}
            onChange={handleCheckboxChange}
          /> */}
          <label htmlFor="checked-checkbox" className="text-2xs">
            <HtmlFragment>{checkBoxContent}</HtmlFragment>
          </label>
        </div>
        <div className="py-40">
          <button
            className="cta btn btn-primary inline-block px-24 py-16 leading-[0.5rem]"
            onClick={handleSettings}
            aria-label="Save"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default Notification;
