import { useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import clsx from "classnames";

import HtmlFragment from "./html-fragment";

const BASE_CLASS = "page-header";

function PageHeader(props) {
  let { title, className = "", border, anchorId = null } = props;
  const router = useRouter();

  const borderTop = border === "borderTop";
  const noBorder = border === "noBorder";

  const scrollToSection = useCallback((scrollToElem) => {
    if (scrollToElem) {
      let elementPosition = scrollToElem.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - 150;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      scrollToSection(document.getElementById(router.query.component));
    }
  }, [router, scrollToSection]);

  return title ? (
    <div className={clsx(BASE_CLASS, className)} id={anchorId}>
      <div className="grid grid-flow-row grid-cols-12 grid-rows-1 gap-16">
        <div
          className={clsx("col-span-12 ipad:col-span-8", {
            "pt-48": !borderTop && !noBorder,
            "border-t border-grey-700 pt-24 ipad:pt-32": borderTop && !noBorder,
          })}
        >
          <div className="font-normal text-grey-600">
            <HtmlFragment>{title}</HtmlFragment>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default PageHeader;
