import clsx from "clsx";

import HtmlFragment from "./html-fragment";
import isEmpty from "lodash.isempty";
import { useHedgingContext } from "@/context/betaContext";
import { formatDecimalNumber } from "../lib/text";

const BASE_CLASS = "total-investment-performance";

function TotalInvestmentPerformance(props) {
  let { tiles } = props;
  const { graphData } = useHedgingContext();

  const currentData =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData.sort(
          (a, b) =>
            new Date(b.MARKET_CLOSE_DATE) - new Date(a.MARKET_CLOSE_DATE)
        )
      : null;

  const sortPerformance =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData?.sort(
          (a, b) =>
            new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
        )
      : [];

  const UNHEDGED_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL
  );

  // const REQUIREDINVESTMENT = Math.floor(
  //   sortPerformance?.[0]?.REQUIREDINVESTMENT
  // );
  // const second_REQUIREDINVESTMENT =
  //   sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL -
  //   sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL;
  // const portfolioOriginalValue = HEDGED_TOTAL + REQUIREDINVESTMENT;
  // const HEDGED_TOTAL = sortPerformance?.[0]?.UNHEDGED_TOTAL;
  // const givenDate = new Date(currentData?.[0]?.MARKET_CLOSE_DATE);
  // givenDate?.setDate(givenDate?.getDate() + 1);

  const CURRENT_HEDGE_TOTAL = Math.floor(
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL
  );

  const second_HEDGED_TOTAL =
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL;

  // Check if MARKET_CLOSE_DATE is valid
  const givenDate =
    currentData?.length > 0 &&
    !isNaN(new Date(currentData?.[0]?.MARKET_CLOSE_DATE))
      ? new Date(currentData?.[0]?.MARKET_CLOSE_DATE)
      : null;
  if (givenDate) {
    givenDate?.setDate(givenDate?.getDate() + 1);
  }

  //calculation for withoutHedge-----------------------------------------------------------
  // recent variables

  const Last_Obj_CURRENT_HEDGE_TOTAL =
    sortPerformance?.[sortPerformance.length - 1]?.CURRENT_HEDGE_TOTAL;

  const Diffrence_Last_Obj_Unhedged =
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL;

  const Diffrence_First_Obj_Unhedged = sortPerformance?.[0]?.UNHEDGED_TOTAL;

  const withoutHedge =
    Diffrence_Last_Obj_Unhedged - Diffrence_First_Obj_Unhedged;

  //calculation for gain and losses--------------------------------------------------------
  // recent variables

  const Hedge_Only_Last_Obj =
    Last_Obj_CURRENT_HEDGE_TOTAL -
    sortPerformance?.[sortPerformance.length - 1]?.UNHEDGED_TOTAL;

  const Hedge_Only_First_Obj =
    sortPerformance?.[0]?.CURRENT_HEDGE_TOTAL -
    sortPerformance?.[0]?.UNHEDGED_TOTAL;

  const gainLosses =
    sortPerformance?.length <= 1
      ? 0
      : Hedge_Only_Last_Obj - Hedge_Only_First_Obj;

  //calculation for withHedge--------------------------------------------------------------
  // recent variables

  const portfolioCurruntValue =
    second_HEDGED_TOTAL + CURRENT_HEDGE_TOTAL - UNHEDGED_TOTAL;

  const First_Obj_CURRENT_HEDGE_TOTAL = Math.floor(
    sortPerformance?.[0]?.CURRENT_HEDGE_TOTAL
  );

  const withHedge =
    sortPerformance?.length <= 1
      ? 0
      : Last_Obj_CURRENT_HEDGE_TOTAL - First_Obj_CURRENT_HEDGE_TOTAL;

  return props ? (
    <div className={clsx(BASE_CLASS)}>
      <div className="grid grid-cols-3 gap-4">
        {tiles?.map((value, key) => {
          return (
            <div
              className="border-r-2 ps-40 first:ps-20 last:border-r-0"
              key={key}
            >
              <div className="">
                <HtmlFragment>{value.name}</HtmlFragment>
              </div>
              <div
                className={clsx(
                  "pt-20 font-mazzardSemiBold text-sm",
                  value.withoutHedge
                    ? withoutHedge > 0
                      ? "text-green"
                      : withoutHedge === 0 || withoutHedge === null
                        ? "text-black"
                        : "text-red"
                    : value.withHedge
                      ? withHedge > 0
                        ? "text-green"
                        : withHedge === 0 ||
                            withHedge === null ||
                            sortPerformance.length < 2
                          ? "text-black"
                          : "text-red"
                      : gainLosses > 0
                        ? "text-green"
                        : gainLosses === 0 ||
                            gainLosses === null ||
                            Number.isNaN(gainLosses) ||
                            sortPerformance.length < 2
                          ? "text-black"
                          : "text-red"
                )}
              >
                {value.withoutHedge
                  ? withoutHedge < 0
                    ? withoutHedge
                      ? sortPerformance.length < 2
                        ? "$0"
                        : `-$${formatDecimalNumber(Math.abs(withoutHedge))}`
                      : "$0"
                    : withoutHedge
                      ? `$${formatDecimalNumber(withoutHedge)}`
                      : "$0"
                  : value.withHedge
                    ? withHedge < 0
                      ? withHedge
                        ? sortPerformance.length < 2
                          ? "$0"
                          : `-$${formatDecimalNumber(Math.abs(withHedge))}`
                        : "$0"
                      : withHedge
                        ? `$${formatDecimalNumber(withHedge)}`
                        : "$0"
                    : gainLosses < 0
                      ? gainLosses
                        ? sortPerformance.length < 2
                          ? "$0"
                          : `-$${formatDecimalNumber(Math.abs(gainLosses))}`
                        : "$0"
                      : gainLosses
                        ? `$${formatDecimalNumber(gainLosses)}`
                        : "$0"}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}

export default TotalInvestmentPerformance;
