import _slugify from "slugify";

export function slugify(payload) {
  if (!payload) return "";

  return _slugify(payload, {
    replacement: "-",
    lower: true,
    strict: true,
    trim: true,
  });
}

export function formatError(message) {
  if (message?.startsWith("Unexpected token")) {
    return "Failed to retrieve page content!";
  } else {
    return message;
  }
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      let str = word === "etfs" ? "ETFs" : word;
      return str.charAt(0).toUpperCase() + str.slice(1);
    })
    .join(" ");
}

export function formatDecimalNumber(str) {
  const number = parseFloat(str);
  if (isNaN(number)) {
    return "0.00";
  }

  const fixedNumber = number.toFixed(2);
  return parseFloat(fixedNumber).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function throwError(error) {
  console.error("throwError: ", error);

  if (!error) return;
  if (typeof error === "string") throw new Error(error);
  if (error?.response?.data?.error) {
    throw new Error(error.response.data.error.toString());
  }
  throw error;
}

export function localStringToNumber(s) {
  return Number(String(s).replace(/[^0-9.,-]+/g, ""));
}

export function allowOnlyNumbers(value) {
  if (!value) return;

  const regex = /^[0-9.,$]+$/;
  const newVal = value
    .split("")
    .filter((f) => f.match(regex))
    .join("");

  return newVal;
}

export function intlFormat(str) {
  const decimal = parseFloat(str).toFixed(2);
  const [num, dec] = decimal.split(".");

  const calcNum = Number(`${num}.${dec}`);
  const showVal =
    new Intl.NumberFormat("en-US").format(num) + (dec ? `.${dec}` : "");

  return { showVal, calcNum };
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { month: "short", day: "2-digit", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

export function formatToSlashDate(inputDate) {
  const date = new Date(inputDate);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export function getEquidistantDates(arr, num) {
  const step = Math.floor((arr.length - 1) / (num - 1));
  const result = [arr[0]]; // Start with the first date

  for (let i = 1; i < num - 1; i++) {
    const index = 1 + i * step; // Calculate index of equidistant date
    result.push(arr[index]);
  }

  result.push(arr[arr.length - 1]); // End with the last date

  return result;
}

export function getEquidistantPercentage(arr) {
  let minVal = Math.min(...arr);
  let maxVal = Math.max(...arr);
  let numValsBetween = 5;
  let step = (maxVal - minVal) / (numValsBetween + 1);

  let newArray = [minVal];
  for (let i = 0; i < numValsBetween; i++) {
    let newVal = minVal + step * (i + 1);
    newArray.push(newVal);
  }
  newArray.push(0);
  newArray.push(maxVal);

  return newArray;
}
