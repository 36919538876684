import React, { createContext, useContext } from "react";
import clsx from "clsx";
import parseHtml, { domToReact } from "html-react-parser";
import DOMPurify from "isomorphic-dompurify";

import each from "lodash.foreach";
import includes from "lodash.includes";
import Image from "next/image";
import { isExternalLinkUrl } from "@/lib/url";

import Link from "./link";

const domPurifyOptions = {
  // allow entry: type URLs
  ALLOWED_URI_REGEXP:
    /^(?:(?:(?:f|ht)tps?|mailto|tel|entry):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
  FORBID_ATTR: [
    "width",
    "height",
    "border",
    "align",
    "cellpadding",
    "cellspacing",
    "style",
    "valign",
  ],
  ADD_TAGS: ["video-player"],
  ADD_ATTR: [],
};

function cleanHtml(html) {
  return html && html.replace(/>\s+</g, "> <");
}

function parse(html, options = {}, context = {}) {
  const {
    transformSrc,
    transformEntryRef,
    useExtLinkIcons,
    noHeadings = true,
  } = {
    ...context,
    ...options,
  };

  const findNode = (node, predicate) => {
    if (predicate(node)) return node;
    let foundNode;
    each(node.children, (child) => {
      foundNode = findNode(child, predicate);
      if (foundNode) return false;
    });
    return foundNode;
  };

  const replace = (node) => {
    if (!node) return null;

    try {
      const { type, name, attribs, children /*, data*/ } = node;

      // headings
      if (noHeadings && type === "tag" && name.match(/h\d/i)) {
        const { class: className, ...otherAttribs } = attribs;

        return (
          <div className={clsx(name, className)} {...otherAttribs}>
            {domToReact(children, { replace })}
          </div>
        );
      }

      // anchors/links
      if (type === "tag" && name === "a") {
        const {
          href,
          "data-entry-ref": entryRef,
          class: className,
          "data-scrollto": scrollto,
          ...otherAttribs
        } = attribs;

        let to = href;
        let isExternalLink = isExternalLinkUrl(to) ? "_blank" : "";
        if (!to && entryRef && transformEntryRef)
          to = transformEntryRef(entryRef);

        const scrollToSection = (id) => {
          const element = document.getElementById(id);
          if (element) {
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - 100;
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        };
        if (scrollto) {
          return (
            <button
              className={clsx(className, "anchor text-blue-200")}
              onClick={() => {
                scrollToSection(scrollto);
              }}
              {...otherAttribs}
            >
              {domToReact(children, { replace })}
            </button>
          );
        } else {
          return (
            <Link
              className={clsx(className, "anchor")}
              to={to}
              target={isExternalLink}
              href={to}
              extlinkiconenabled={useExtLinkIcons}
              {...otherAttribs}
            >
              {domToReact(children, { replace })}
            </Link>
          );
        }
      }

      // unordered-list
      if (type === "tag" && name === "ul") {
        const { class: className, ...otherAttribs } = attribs;

        return (
          <ul {...otherAttribs} className={clsx(className, "list-disc pl-28")}>
            {domToReact(children, { replace })}
          </ul>
        );
      }

      // ordered-list
      if (type === "tag" && name === "ol") {
        const { class: className, type, ...otherAttribs } = attribs;

        return (
          <ol
            {...otherAttribs}
            className={clsx(className, `list-decimal pl-28`)}
          >
            {domToReact(children, { replace })}
          </ol>
        );
      }

      // list items
      // if (type === "tag" && name === "li") {
      //   const Wrapper =
      //     children.length === 1 && children[0]?.type === "text"
      //       ? "p"
      //       : Fragment;
      //   return (
      //     <li {...attribs}>
      //       <Wrapper>{domToReact(children, { replace })}</Wrapper>
      //     </li>
      //   );
      // }

      // images
      if (type === "tag" && name === "img") {
        let { src, class: classStr, ...otherAttribs } = attribs;
        if (transformSrc) src = transformSrc(src);
        const classes = (classStr || "").toLowerCase().split(/\s+/);
        const className = clsx(
          "mw-100",
          includes(classes, "align-left") && "w-50 float-start me-3",
          includes(classes, "align-right") && "w-50 float-end ms-3"
        );

        // eslint-disable-next-line jsx-a11y/alt-text
        return <Image className={className} src={src} {...otherAttribs} />;
      }

      // // tables
      if (type === "tag" && name === "table") {
        const { class: className, ...otherAttribs } = attribs;
        return (
          <div className="container">
            <table {...otherAttribs} className={clsx("table", className)}>
              {domToReact(children, { replace })}
            </table>
          </div>
        );
      }

      if (type === "tag" && name === "tbody") {
        const { class: className, ...otherAttribs } = attribs;
        return (
          <tbody {...otherAttribs} className={clsx("divider", className)}>
            {domToReact(children, { replace })}
          </tbody>
        );
      }

      if (type === "tag" && name === "blockquote") {
        // blockquote
        const { class: className, ...otherAttribs } = attribs;
        return (
          <blockquote
            className={clsx("blockquote", className)}
            {...otherAttribs}
          >
            {domToReact(children, { replace })}
          </blockquote>
        );
      }
    } catch (error) {
      console.error("Error replacing content in HTML Fragment node:", node);
    }
  };

  return parseHtml(DOMPurify.sanitize(cleanHtml(html), domPurifyOptions), {
    replace,
  });
}

export default function HtmlFragment({ children, text, options }) {
  const context = useContext(HtmlFragmentContext);
  const html = (children || text)?.toString();
  return <>{parse(html, options, context)}</>;
}

export const HtmlFragmentContext = createContext({});
