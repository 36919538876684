import React from "react";
import clsx from "clsx";
import { useWindowSize } from "react-use";

import isEmpty from "lodash.isempty";
import map from "lodash.map";
import get from "lodash.get";

import { HtmlFragment } from "./*";

const BASE_CLASS = "feature-table";

function FeatureTable(props) {
  const { className = "", featureTableList, header, footNote } = props;
  const mobile = get(useWindowSize(), "width") < 1200 ? true : false;

  return (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="grid grid-cols-12 gap-x-16 gap-y-24 py-24">
        {header ? (
          <h4 className="col-span-12 text-left md:font-mazzardSemiBold ipad:text-right">
            {header}
          </h4>
        ) : null}
        <div className="relative col-span-12">
          {!isEmpty(featureTableList) ? (
            <Table featureTableList={featureTableList} />
          ) : null}
          {mobile ? (
            <div className="absolute right-0 top-0 h-full w-40 bg-gradient-to-r from-transparent from-10% to-white to-90%"></div>
          ) : null}
        </div>
        {!isEmpty(footNote) ? (
          <div className="text-body-xs col-span-12 font-mazzard text-grey-600">
            <HtmlFragment>{footNote}</HtmlFragment>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function Table(props) {
  const { featureTableList } = props;
  featureTableList.filter((f) => {
    "contentType" in f ? delete f.contentType : null;
    "name" in f ? delete f.name : null;
    return f;
  });
  const headings = Object.values(featureTableList[0]);
  const rows = featureTableList?.filter((f, i) => i !== 0);

  const renderHeadingRow = (heading, index) => {
    return (
      <Cell
        key={`heading-${index}`}
        content={heading}
        header={true}
        first={index === 0}
      />
    );
  };

  const renderRow = (row, rowIndex) => {
    const keys = Object.keys(row);

    return (
      <tr className="odd:bg-grey-300 even:bg-grey-200" key={`row-${rowIndex}`}>
        {map(keys, (key, i) => {
          return <Cell key={key} content={row[key]} />;
        })}
      </tr>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto xl:overflow-x-visible">
        <div className="inline-block min-w-full">
          <div className="overflow-hidden">
            <table
              className="min-w-full table-auto border-separate border-spacing-x-[2px]"
              tabIndex="0"
            >
              <thead>
                <tr key="heading">{map(headings, renderHeadingRow)}</tr>
              </thead>
              <tbody>{map(rows, renderRow)}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function Cell(props) {
  const { header, content, first } = props;
  if (header) {
    return (
      <th
        className={clsx(
          "bg-grey-200 px-8 py-12 font-mazzardSemiBold text-md first:bg-white first:align-bottom xl:min-w-[auto]",
          {
            "min-w-[240px]": first,
            "min-w-[116px]": !first,
          }
        )}
      >
        {content}
      </th>
    );
  }

  return (
    <td className="text-body-lg px-8 py-12 text-black">
      {content === true ? (
        <span className="m-auto block h-16 w-16 rotate-45 bg-purple-100"></span>
      ) : (
        <HtmlFragment>{content}</HtmlFragment>
      )}
    </td>
  );
}

export default FeatureTable;
