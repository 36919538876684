// This is for modify hedge percentage calculations
export const modifyHedgePercent = (getHedgeData) => {
  // Calculate apiExposure using hedgeholding data
  const apiExposure =
    getHedgeData?.hedgeholding?.HEDGED_UNITS *
    getHedgeData?.hedgeholding?.CURRENT_INDEX_RATE *
    Math.abs(getHedgeData?.hedgeholding?.CURRENT_INDEX_BETA);

  // Get the selected beta ticker
  const isSelectedBeta2 = getHedgeData?.hedgeholding?.INDEX_TICKER;

  // Calculate apiBetaValue based on holdings data
  const apiBetaValue = getHedgeData?.holdings?.reduce((acc, curr) => {
    // Check if VALUE is a string before applying replace, then parse to float
    const value =
      typeof curr.VALUE === "string"
        ? parseFloat(curr.VALUE.replace(/,/g, "") || 0)
        : parseFloat(curr.VALUE || 0);

    // Check if the selected beta field exists in the current object
    if (curr.hasOwnProperty(`beta_${isSelectedBeta2?.toLowerCase()}`)) {
      const selectedBeta = parseFloat(
        curr[`beta_${isSelectedBeta2?.toLowerCase()}`] || 0
      );
      return acc + value * selectedBeta;
    }
    return acc;
  }, 0);

  // Calculate the current hedge percentage
  const currentHedgePercentage = (apiExposure / apiBetaValue) * 100;
  return currentHedgePercentage;
};

// ---------------------------------------------------------------------------------------------------------------------------------//

// This is for negative beta error message
export const negativeBetaError = (getHedgeData, editTableData) => {
  const isSelectedBeta = getHedgeData?.hedgeholding?.INDEX_TICKER;
  const editData = editTableData?.filter((val) => val.beta_gspc_indx);

  // This is for save Holdings button (if total is negative then button is disable)
  const totalEditBetaValue = editData?.reduce((acc, curr) => {
    // Check if VALUE is a string before applying replace
    const value =
      typeof curr.VALUE === "string"
        ? parseFloat(curr.VALUE.replace(/,/g, "") || 0)
        : parseFloat(curr.VALUE || 0);

    // Check if the selected beta field exists in the current object
    if (curr.hasOwnProperty(`beta_${isSelectedBeta?.toLowerCase()}`)) {
      const selectedBeta = parseFloat(
        curr[`beta_${isSelectedBeta?.toLowerCase()}`] || 0
      );
      return acc + value * selectedBeta;
    }
  }, 0);
  return totalEditBetaValue;
};
