import { useState } from "react";
import clsx from "clsx";

import { HtmlFragment as HF } from "./*";

function DisableState(props) {
  const { children, info, tooltipLeft, fontColor } = props;
  const [showInfo, setShowInfo] = useState(false);

  const handleShowInfo = () => {
    setShowInfo((prev) => !prev);
  };

  return (
    <span className="flex gap-x-4">
      {info ? (
        <span
          className="relative h-fit"
          tabIndex={0}
          onMouseEnter={handleShowInfo}
          onMouseLeave={handleShowInfo}
        >
          {children}
          <div
            className={clsx(
              `${tooltipLeft ? "left-[-260px] top-1/2" : "left-full top-1/2"}`,
              `${fontColor === "black" && "text-black"}`,
              "text-body-xs absolute z-[99] min-w-[280px] border border-grey-400 bg-white p-12",
              { block: showInfo, hidden: !showInfo }
            )}
          >
            <HF>{info}</HF>
          </div>
        </span>
      ) : null}
    </span>
  );
}

export default DisableState;
