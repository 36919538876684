import LineTitle from "./line-title";
import { useHedgingContext } from "@/context/betaContext";

const BASE_CLASS = "my-result";

function MyResult(props) {
  let { results } = props;
  const { getHedgeData } = useHedgingContext();
  return props ? (
    <div>
      {results?.title ? (
        <LineTitle text={results?.title} container={false} />
      ) : null}
      <div className="grid grid-cols-1 items-end gap-x-20 gap-y-32 pt-24 lg:grid-cols-5">
        <div className="col-span-3">
          <ul className="flex flex-col gap-y-16">
            <li>Hedge Created: </li>
            <li>% of holdings hedged (original): </li>
            <li>Hedge type: </li>
          </ul>
        </div>
        <div className="col-span-2">
          <ul className="flex flex-col gap-y-16 font-mazzardBold">
            <li>
              {formatToSlashDate(getHedgeData?.hedgeholding?.CREATED_DATE)}
            </li>
            <li>{Math.floor(getHedgeData?.hedgeholding?.HEDGE_PERCENT)}%</li>
            <li>
              {getHedgeData?.hedgeholding?.MULTIPLIER}{" "}
              {getHedgeData?.hedgeholding?.INDEX_NAME.includes("Nasdaq")
                ? `${getHedgeData?.hedgeholding?.INDEX_NAME?.toUpperCase()} ETF`
                : getHedgeData?.hedgeholding?.INDEX_NAME}
            </li>
          </ul>
        </div>
      </div>
    </div>
  ) : null;
}

export default MyResult;
