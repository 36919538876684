import isEmpty from "lodash.isempty";
import { createContext, useContext, useRef, useState } from "react";
import { Value } from "sass";

const HedgingContext = createContext();

export const HedgingProvider = ({ children }) => {
  const intialData = [
    {
      ticker: "",
      ticker_name: "",
      market_close_value: "",
      weight: "",
      estUnit: "",
      value: "",
      unitPost: "",
      valuePost: "",
    },
  ];

  const targetRef = useRef(null);

  const [betaValue, setBetaValue] = useState(0);
  const [isSelectIndex, setIsSelectIndex] = useState("");
  const [isSelectMultiple, setIsSelectMultiple] = useState("");
  const [isSelectPortfolio, setIsSelectPortfolio] = useState("");
  const [isSelectedBeta, setIsSelectedBeta] = useState("gspc.indx");
  const [isTotalPortfolio, setIsTotalPortfolio] = useState(0);
  const [isHoldings, setIsHoldings] = useState(false);
  const [isRequiredInvestment, setIsRequiredInvestment] = useState(0);
  const [isDropChange, setIsDropChange] = useState(false);
  const [tableData, setTableData] = useState(intialData);
  const [rangeData, setRangeData] = useState(50);
  const [hedgeExpo, setHedgeExpo] = useState(0);
  const [betaId, setBetaId] = useState(-0.9764);
  const [getHedgeData, setGetHedgeData] = useState([]);
  const [getAllPortfolio, setGetAllPortfolio] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [isDropBeta, setIsDropBeta] = useState(false);
  const [isTableData, setIsTableData] = useState(false);
  const [isNewRow, setIsNewRow] = useState(false);
  const [deletePortfolioid, setDeletePortfolioid] = useState(0);
  const [isIdToken, setisIdToken] = useState("");
  const [selectedIndexId, setSelectedIndexId] = useState(1);
  const [portfolioId, setPortfolioId] = useState(0);
  const [selectedTickerBeta, setSelectedTickerBeta] = useState(-1.0);
  const [editHolding, setEditHolding] = useState(false);
  const [saveHoldings, setSaveHoldings] = useState(false);
  const [updateDropLabel, setUpdateDropLabel] = useState(false);
  const [changeEditLabel, setChangeEditLabel] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);
  const [holdingShow, setHoldingShow] = useState(false);
  const [isAlert, setIsAlert] = useState({
    open: false,
    message: "",
    error: false,
  });
  const [userProfile, setUserProfile] = useState({});
  const [hedgePercent, setHedgePercent] = useState({ above: 5, below: 5 });
  const [hedgeTotal, setHedgeTotal] = useState(0);
  const [active, setActive] = useState(0);
  const [hedgePercentage, setHedgePercentage] = useState(0);
  const [currentHedgeValue, setCurrentHedgeValue] = useState(0);
  const [getCurrRate, setGetCurrRate] = useState(0);
  const [modifyHedge, setModifyHedge] = useState(false);
  const [getTickerBeta, setGetTickerBeta] = useState(0);
  const [modifyHedgeModal, setModifyHedgeModal] = useState(false);
  const [investmentLoader, setInvestmentLoader] = useState(false);
  const [newInverseEtf, setNewInverseEtf] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [getHoldingLoader, setGetHoldingLoader] = useState(false);
  const [editTableData, setEditTableData] = useState();
  const [isLoginTableData, setIsLoginTableData] = useState([]);
  const [editSortData, setEditSortData] = useState([]);
  const [editDataEnable, setEditDataEnable] = useState(false);
  const [tabDataChange, setTabDataChange] = useState(false);
  const [recall, setRecall] = useState(true);
  const [setUnit, setSetUnit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rebalanceNew, setRebalanceNew] = useState(false);
  const [modifyHedgeNew, setHandleModifyHedgeNew] = useState(false);
  const [selectNotification, setSelectNotification] = useState("No Alerts");
  const [scrollView, setScrollView] = useState(false);
  const [settingPortfolioId, setSettingPortfolioId] = useState("");
  const [changeNotificationTab, setChangeNotificationTab] = useState(false);
  const [calendarSubitem, setCalendarSubitem] = useState("No Alerts");
  const [hedgePercentBelow, setHedgePercentBelow] = useState(5);
  const [hedgePercentAbove, setHedgePercentAbove] = useState(5);
  const [portfolioName, setPortfolioName] = useState("");
  const [updatePortfolioName, setUpdatePortfolioName] = useState(false);
  const [existingNameError, setExistingNameError] = useState(false);
  const [nameLengthError, setNameLengthError] = useState(false);
  const [updateModifyHedge, setUpdateModifyHedge] = useState(false);
  const [isModifyHedge, setIsModifyHedge] = useState(false);
  const [modifyRange, setModifyRange] = useState(false);
  const [changePercent, setChangePercent] = useState(false);
  const [getCurrRateValue, setGetCurrRateValue] = useState(false);
  const [totalHedgePercent, setTotalHedgePercent] = useState("50%");
  const [getIndexData, setGetIndexData] = useState(0);
  const [updateDropdown, setUpdateDropdown] = useState(false);
  const [handleWarning, setHandleWarning] = useState(false);
  const [betaFlowData, setBetaFlowData] = useState(false);
  const [noBetaData, setNoBetaData] = useState([]);
  const [tickerCheck, setTickerCheck] = useState(false);
  const [negativeTickers, setNegativeTickers] = useState([]);
  const [defaultUpdateTicker, setDefaultUpdateTicker] = useState(true);
  const [checkDropAbove, setCheckDropAbove] = useState(false);
  const [checkDropBelow, setCheckDropBelow] = useState(false);
  const [blankValues, setBlankValues] = useState(false);
  const [getTickerResponse, setGetTickerResponse] = useState(false);
  const [editDeleteValue, setEditDeleteValue] = useState(false);
  const [tabClicked, setTabClicked] = useState(false);
  const [tableIndex, setTableIndex] = useState(0);
  const [settingTabActive, setSettingTabActive] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [editBetaValue, setEditBetaValue] = useState(0);
  const [resetCode, setResetCode] = useState(false);
  const [editRebalance, setEditRebalance] = useState(false);
  const [isModifingHedge, setIsModifingHedge] = useState(false);

  const handleUserProfile = (obj) => {
    setUserProfile(obj);
  };
  const handleIsModifingHedge = (obj) => {
    setIsModifingHedge(obj);
  };

  const handleIsAlert = (obj) => {
    setIsAlert((prev) => (!isEmpty(obj) ? obj : { ...prev, open: false }));
  };

  const handleBetaValue = (value) => {
    setBetaValue(value);
  };

  const handleInvestment = (value) => {
    setIsRequiredInvestment(value);
  };

  const handleSelectIndex = (value) => {
    setIsSelectIndex(value);
  };

  const handleSelectMultiple = (value) => {
    setIsSelectMultiple(value);
  };

  const handleSelectPortfolio = (value) => {
    setIsSelectPortfolio(value);
  };

  const handleIsSelectBeta = (value) => {
    setIsSelectedBeta(value);
  };

  const handleIsTotalPortfolio = (value) => {
    setIsTotalPortfolio(value);
  };

  const handleIsHoldings = (value) => {
    setIsHoldings(value);
  };

  const handleIsDropChange = (value) => {
    setIsDropChange(value);
  };

  const handleTableData = (value) => {
    setTableData(value);
  };

  const handleRangeData = (value) => {
    setRangeData(value);
  };

  const handleHedgeExpo = (value) => {
    setHedgeExpo(value);
  };

  const handleBetaId = (value) => {
    setBetaId(value);
  };

  const handleGetHedgeData = (value) => {
    setGetHedgeData(value);
  };

  const handleAllPortfolio = (value) => {
    setGetAllPortfolio(value);
  };

  const handleGraphData = (value) => {
    setGraphData(value);
  };

  const handleIsDropBeta = (value) => {
    setIsDropBeta(value);
  };

  const handleIsTableData = (value) => {
    setIsTableData(value);
  };

  const handleAddNewRow = (value) => {
    setIsNewRow(value);
  };

  const handleDeletePortfolioId = (value) => {
    setDeletePortfolioid(value);
  };

  const handleIdToken = (value) => {
    setisIdToken(value);
  };

  const handleSelectedIndexId = (value) => {
    setSelectedIndexId(value);
  };

  const handleSetPortfolioId = (value) => {
    setPortfolioId(value);
  };

  const handleSelectedTickerBeta = (value) => {
    setSelectedTickerBeta(value);
  };

  const handleEditHolding = (value) => {
    setEditHolding(value);
  };

  const handleEditRebalance = (value) => {
    setEditRebalance(value);
  };

  const handleSaveHoldings = (value) => {
    setSaveHoldings(value);
  };

  const handleUpdateDropLabel = (value) => {
    setUpdateDropLabel(value);
  };

  const handleChangeEditLabel = (value) => {
    setChangeEditLabel(value);
  };

  const handleLoaderShow = (value) => {
    setLoaderShow(value);
  };

  const handleHoldingShow = (value) => {
    setHoldingShow(value);
  };

  const handleHedgePercent = (value) => {
    setHedgePercent(value);
  };

  const handleHedgeTotal = (value) => {
    setHedgeTotal(value);
  };

  const handleActive = (value) => {
    setActive(value);
  };

  const handleHedgePercentage = (value) => {
    setHedgePercentage(value);
  };

  const handleCurrentHedgeValue = (value) => {
    setCurrentHedgeValue(value);
  };

  const handleGetTickerBeta = (value) => {
    setGetTickerBeta(value);
  };

  const handleModifyHedge = (value) => {
    setModifyHedge(value);
  };

  const handleHedgeModal = (value) => {
    setModifyHedgeModal(value);
  };

  const handleInvestmentLoader = (value) => {
    setInvestmentLoader(value);
  };

  const handleNewInverseEtf = (value) => {
    setNewInverseEtf(value);
  };

  const handleDisableButton = (value) => {
    setDisableButton(value);
  };

  const handleGetHoldingLoader = (value) => {
    setGetHoldingLoader(value);
  };

  const handleEditTableData = (value) => {
    setEditTableData(value);
  };

  const handleIsLoginTableData = (value) => {
    setIsLoginTableData(value);
  };

  const handleEditSortData = (value) => {
    setEditSortData(value);
  };

  const handleEditDataEnable = (value) => {
    setEditDataEnable(value);
  };

  const handleTabDataChange = (value) => {
    setTabDataChange(value);
  };

  const handleRecall = (value) => {
    setRecall(value);
  };

  const handleSetUnit = (value) => {
    setSetUnit(value);
  };

  const handleShowModal = (value) => {
    setShowModal(value);
  };

  const handleRebalanceNew = (value) => {
    setRebalanceNew(value);
  };

  const handleModifyHedgeNew = (value) => {
    setHandleModifyHedgeNew(value);
  };

  const handleSelectNotification = (value) => {
    setSelectNotification(value);
  };

  const handleScrollView = (value) => {
    setScrollView(value);
  };

  const handleSettingPortfolioId = (value) => {
    setSettingPortfolioId(value);
  };

  const handleChangeNotificationTab = (value) => {
    setChangeNotificationTab(value);
  };

  const handleCalendarSubitem = (value) => {
    setCalendarSubitem(value);
  };

  const handleHedgePercentBelow = (value) => {
    setHedgePercentBelow(value);
  };

  const handleHedgePercentAbove = (value) => {
    setHedgePercentAbove(value);
  };

  const handlePortfolioName = (value) => {
    setPortfolioName(value);
  };

  const handleUpdatePortfolioName = (value) => {
    setUpdatePortfolioName(value);
  };

  const handleExistingNameError = (value) => {
    setExistingNameError(value);
  };

  const handleNameLengthError = (value) => [setNameLengthError(value)];

  const handleUpdateModifyHedge = (value) => {
    setUpdateModifyHedge(value);
  };

  const handleIsModifyHedge = (value) => {
    setIsModifyHedge(value);
  };

  const handleModifyRange = (value) => {
    setModifyRange(value);
  };

  const handleChangePercent = (value) => {
    setChangePercent(value);
  };

  const handleGetCurrRateValue = (value) => {
    setGetCurrRateValue(value);
  };

  const handleTotalHedgePercent = (value) => {
    setTotalHedgePercent(value);
  };

  const handleGetIndexData = (value) => {
    setGetIndexData(value);
  };

  const handleUpdateDropdown = (value) => {
    setUpdateDropdown(value);
  };

  const handleHandleWarning = (value) => {
    setHandleWarning(value);
  };

  const handleBetaFlowData = (value) => {
    setBetaFlowData(value);
  };

  const handleNoBetaData = (value) => {
    setNoBetaData(value);
  };

  const handleTickerCheck = (value) => {
    setTickerCheck(value);
  };

  const handleNegativeTickers = (value) => {
    setNegativeTickers(value);
  };

  const handleDefaultUpdateTicker = (value) => {
    setDefaultUpdateTicker(value);
  };

  const handleCheckDropAbove = (value) => {
    setCheckDropAbove(value);
  };

  const handleCheckDropBelow = (value) => {
    setCheckDropBelow(value);
  };

  const handleBlankValues = (value) => {
    setBlankValues(value);
  };

  const handleGetTickerResponse = (value) => {
    setGetTickerResponse(value);
  };

  const handleEditDeleteValue = (value) => {
    setEditDeleteValue(value);
  };

  const handleTabClicked = (value) => {
    setTabClicked(value);
  };

  const handleTableIndex = (value) => {
    setTableIndex(value);
  };

  const handleSettingTabActive = (value) => {
    setSettingTabActive(value);
  };

  const handleEditValue = (value) => {
    setEditValue(value);
  };

  const handleEditBetaValue = (value) => {
    setEditBetaValue(value);
  };

  const handleResetCode = (value) => {
    setResetCode(value);
  };

  return (
    <HedgingContext.Provider
      value={{
        betaValue,
        isSelectIndex,
        isSelectMultiple,
        isSelectPortfolio,
        isSelectedBeta,
        isTotalPortfolio,
        isHoldings,
        isRequiredInvestment,
        isDropChange,
        tableData,
        rangeData,
        hedgeExpo,
        betaId,
        getHedgeData,
        getAllPortfolio,
        isDropBeta,
        isTableData,
        isNewRow,
        deletePortfolioid,
        isIdToken,
        selectedIndexId,
        portfolioId,
        selectedTickerBeta,
        editHolding,
        isAlert,
        userProfile,
        graphData,
        saveHoldings,
        updateDropLabel,
        changeEditLabel,
        loaderShow,
        holdingShow,
        hedgePercent,
        hedgeTotal,
        active,
        hedgePercentage,
        currentHedgeValue,
        getCurrRate,
        modifyHedge,
        getTickerBeta,
        modifyHedgeModal,
        investmentLoader,
        newInverseEtf,
        disableButton,
        getHoldingLoader,
        editTableData,
        isLoginTableData,
        editSortData,
        editDataEnable,
        tabDataChange,
        recall,
        setUnit,
        showModal,
        rebalanceNew,
        modifyHedgeNew,
        selectNotification,
        scrollView,
        targetRef,
        settingPortfolioId,
        changeNotificationTab,
        calendarSubitem,
        hedgePercentBelow,
        hedgePercentAbove,
        portfolioName,
        updatePortfolioName,
        existingNameError,
        nameLengthError,
        updateModifyHedge,
        isModifyHedge,
        modifyRange,
        changePercent,
        getCurrRateValue,
        totalHedgePercent,
        getIndexData,
        updateDropdown,
        handleWarning,
        betaFlowData,
        noBetaData,
        tickerCheck,
        negativeTickers,
        defaultUpdateTicker,
        checkDropBelow,
        checkDropAbove,
        blankValues,
        tabClicked,
        tableIndex,
        editValue,
        editBetaValue,
        getTickerResponse,
        editDeleteValue,
        settingTabActive,
        resetCode,
        editRebalance,
        isModifingHedge,
        handleCalendarSubitem,
        handleGraphData,
        setDeletePortfolioid,
        setIsRequiredInvestment,
        handleBetaValue,
        handleSelectIndex,
        handleSelectMultiple,
        handleSelectPortfolio,
        handleIsSelectBeta,
        handleIsTotalPortfolio,
        handleIsHoldings,
        handleInvestment,
        handleIsDropChange,
        handleTableData,
        handleRangeData,
        handleHedgeExpo,
        handleBetaId,
        handleGetHedgeData,
        handleAllPortfolio,
        handleIsDropBeta,
        handleIsTableData,
        handleAddNewRow,
        handleDeletePortfolioId,
        handleIdToken,
        handleSelectedIndexId,
        handleSetPortfolioId,
        handleSelectedTickerBeta,
        handleEditHolding,
        handleIsAlert,
        handleUserProfile,
        handleSaveHoldings,
        handleUpdateDropLabel,
        handleChangeEditLabel,
        handleLoaderShow,
        handleHoldingShow,
        handleHedgePercent,
        handleHedgeTotal,
        handleActive,
        handleHedgePercentage,
        handleCurrentHedgeValue,
        handleModifyHedge,
        handleGetTickerBeta,
        handleHedgeModal,
        handleInvestmentLoader,
        handleNewInverseEtf,
        handleDisableButton,
        handleGetHoldingLoader,
        handleEditTableData,
        handleIsLoginTableData,
        handleEditSortData,
        handleEditDataEnable,
        handleTabDataChange,
        handleRecall,
        handleSetUnit,
        handleShowModal,
        handleRebalanceNew,
        handleModifyHedgeNew,
        handleSelectNotification,
        handleScrollView,
        handleSettingPortfolioId,
        handleChangeNotificationTab,
        handleHedgePercentBelow,
        handleHedgePercentAbove,
        handlePortfolioName,
        handleUpdatePortfolioName,
        handleExistingNameError,
        handleNameLengthError,
        handleUpdateModifyHedge,
        handleIsModifyHedge,
        handleModifyRange,
        handleChangePercent,
        handleGetCurrRateValue,
        handleTotalHedgePercent,
        handleGetIndexData,
        handleUpdateDropdown,
        handleHandleWarning,
        handleBetaFlowData,
        handleNoBetaData,
        handleTickerCheck,
        handleNegativeTickers,
        handleDefaultUpdateTicker,
        handleCheckDropAbove,
        handleCheckDropBelow,
        handleBlankValues,
        handleGetTickerResponse,
        handleEditDeleteValue,
        handleTabClicked,
        handleTableIndex,
        handleSettingTabActive,
        handleEditValue,
        handleEditBetaValue,
        handleResetCode,
        handleEditRebalance,
        handleIsModifingHedge,
      }}
    >
      {children}
    </HedgingContext.Provider>
  );
};

export const useHedgingContext = () => {
  return useContext(HedgingContext);
};
