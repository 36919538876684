import NextLink from "next/link";
import clsx from "clsx";

import config from "@/config";
import { getFileTypeForPath, isExternalLinkUrl } from "@/lib/url";

const internalHosts = config.internalHosts?.split(/[\s\n,]+/);

function Link(props) {
  let {
    children,
    className,
    download,
    href: _to,
    isExternal,
    openInNewTab = false,
    prefetch = false,
    ...otherProps
  } = props;

  const to = typeof _to === "object" ? _to : { pathname: _to };
  const { pathname } = to;
  const fileExtension = getFileTypeForPath(pathname);
  const isDownload = !!download;
  const isEmail = pathname?.match(/^mailto:/i);
  const isPhone = pathname?.match(/^tel:/i) || pathname?.match(/^sms:/i);

  isExternal ??= isExternalLinkUrl(pathname, internalHosts);

  className = clsx(
    className,
    isExternal && "external-link",
    isDownload && "download-link",
    fileExtension && "file-link",
    isEmail && "email-link",
    isPhone && "phone-link"
  );

  const href = pathname;

  if (openInNewTab || isExternal || isDownload || fileExtension) {
    let target;
    if (openInNewTab === false) target = undefined;
    else if (
      openInNewTab === "_blank" ||
      isExternal ||
      isDownload ||
      fileExtension
    )
      target = "_blank";

    return (
      <a
        className={className}
        download={!!fileExtension && !!openInNewTab}
        href={href}
        rel="noreferrer noopener"
        target={target}
        {...otherProps}
      >
        {children}
        {target && fileExtension && (
          <span className="visually-hidden">
            This links to a {fileExtension} file
          </span>
        )}
        {target && (
          <span className="visually-hidden">This link opens a new window</span>
        )}
      </a>
    );
  }

  if (isEmail || isPhone)
    return <a {...{ className, href, ...otherProps }}>{children}</a>;

  return (
    <NextLink {...{ className, href, prefetch, ...otherProps }}>
      {children}
    </NextLink>
  );
}

export default Link;
