import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useHedgingContext } from "@/context/betaContext";

import { formatDate, getEquidistantPercentage } from "@/lib/text";
import isEmpty from "lodash.isempty";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function LineChart(props) {
  const { text, apiData } = props;
  const { graphData } = useHedgingContext();

  // Verify the result
  const handleMessageChange = (event) => {
    const dataArray = JSON.parse(event.target.value);
    setLineJson(event.target.value);
  };

  const getEquidistantDates = (dates, count) => {
    if (dates.length <= count) return dates;

    const totalDates = dates.length;
    const interval = (totalDates - 1) / (count - 1);
    const result = [dates[0]];

    for (let i = 1; i < count - 1; i++) {
      const index = Math.min(Math.round(i * interval), totalDates - 1);
      if (result[result.length - 1] !== dates[index]) {
        result.push(dates[index]);
      }
    }

    result.push(dates[dates.length - 1]);

    return result;
  };

  // All created dates
  const graphDates = useMemo(() => {
    if (!isEmpty(graphData) && Array.isArray(graphData)) {
      return graphData
        .sort((a, b) => new Date(a.CREATED_DATE) - new Date(b.CREATED_DATE))
        .reduce((acc, item, index) => {
          const dateToFormat =
            index === 0 ? item.CREATED_DATE : item.CREATED_DATE;
          acc.push(formatDate(dateToFormat));
          return acc;
        }, []);
    }
    return null;
  }, [graphData]);

  // equidistant dates (range calculation betwwen dates)
  const newGraphDates = useMemo(
    () =>
      !isEmpty(graphDates)
        ? getEquidistantDates(graphDates?.slice(0), 5)
        : null,
    [graphDates]
  );

  // all hedged percentages
  const hedgedValues =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData
          ?.filter((f) => formatDate(f.MARKET_CLOSE_DATE))
          ?.sort(
            (a, b) =>
              new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
          )
          ?.reduce((acc, item, i) => {
            acc = [...acc, i === 0 ? 0 : item.HEDGE_TOTAL_DIFFRENCE];

            return acc;
          }, [])
      : null;

  // all unhedged percentages
  const unHedgedValues = useMemo(
    () =>
      !isEmpty(graphData) && Array.isArray(graphData)
        ? graphData
            ?.filter((f) => {
              return formatDate(f.MARKET_CLOSE_DATE);
            })
            ?.sort(
              (a, b) =>
                new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
            )
            ?.reduce((acc, item, i) => {
              acc = [...acc, i === 0 ? 0 : item.UNHEDGED_TOTAL_DIFFRENCE];

              return acc;
            }, [])
        : null,
    [graphData]
  );

  //equidistant percentages
  const percentages = useMemo(() => {
    return !isEmpty(hedgedValues) && !isEmpty(unHedgedValues)
      ? getEquidistantPercentage(
          [...hedgedValues, ...unHedgedValues]?.sort((a, b) => b - a)
        )
      : null;
  }, [hedgedValues, unHedgedValues]);

  const data = {
    labels: graphDates,
    datasets: [
      {
        label: "Holdings with Hedge",
        data: hedgedValues,
        borderColor: "#415580",
        backgroundColor: "#415580",
        fill: false,
        tension: 1,
        pointRadius: 1,
      },
      {
        label: "Holdings without Hedge",
        data: unHedgedValues,
        borderColor: "#771B85",
        backgroundColor: "#771B85",
        fill: false,
        tension: 0,
        pointRadius: 1,
      },
    ],
  };

  // let lastLabel = ""; // Variable to store the last label displayed

  const options = {
    responsive: true,
    lineTension: 0.1,
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
        callbacks: {
          title: function (tooltipItems, data) {
            return data?.labels?.[tooltipItems[0].index];
          },
          label: function (context) {
            let label = "";

            if (context?.parsed?.y !== null) {
              if (context?.parsed?.y >= 0) {
                label += ` $${Number(context?.parsed?.y)?.toFixed(2)}`;
              } else {
                label += ` -$${Math.abs(
                  Number(context?.parsed?.y)?.toFixed(2)
                )}`;
              }
            }

            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "top",
        align: "start",
        labels: {
          padding: 20,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 30,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // true : if you need x axis line
        },
        border: {
          display: false,
        },
        ticks: {
          font: { size: 10 },
          // maxTicksLimit: 6,

          // old approch :-
          callback: function (value, index) {
            const text = newGraphDates?.includes(this.getLabelForValue(value))
              ? `${this.getLabelForValue(value)}`
              : "";
            return text;
          },

          // with variable approch :-
          // callback: function (value) {
          //   const label = this.getLabelForValue(value);

          //   // Only display the label if it's different from the last displayed label
          //   if (label !== lastLabel && newGraphDates?.includes(label)) {
          //     lastLabel = label; // Update lastLabel to the current label
          //     return label;
          //   } else {
          //     return ""; // Skip if it's the same as the last displayed label
          //   }
          // },

          // with variable approch :- greater than 4 and lesser than 4
          // callback: function (value, index) {
          //   const label = this.getLabelForValue(value);
          //   if (newGraphDates?.length > 4) {
          //     // Only display the label if it's different from the last displayed label
          //     if (label !== lastLabel && newGraphDates?.includes(label)) {
          //       lastLabel = label; // Update lastLabel to the current label
          //       return label;
          //     } else {
          //       return ""; // Skip if it's the same as the last displayed label
          //     }
          //   } else {
          //     const text = newGraphDates?.includes(this.getLabelForValue(value))
          //       ? `${this.getLabelForValue(value)}`
          //       : "";
          //     // const filterDates = text.filter(() =)
          //     return text;
          //   }
          // },

          // if value is more than 5
          // callback: function (value) {
          //   const label = this.getLabelForValue(value);

          //   // If there are 5 or fewer values, allow labels to repeat
          //   if (newGraphDates?.length > 5) {
          //     return label; // Display all labels without filtering
          //   } else {
          //     // If more than 5 values, apply duplicate-removal logic
          //     if (label !== lastLabel && newGraphDates.includes(label)) {
          //       lastLabel = label; // Update lastLabel to the current label
          //       return label;
          //     } else {
          //       return ""; // Skip if it's the same as the last displayed label
          //     }
          //   }
          // },

          maxRotation: 0,
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: false,
        border: {
          display: false,
        },
        grid: {
          color: (context) => {
            if (context.tick?.value === 0) {
              return "rgba(0,0,0,1)";
            } else {
              return "rgba(116,120,141,0.3)";
            }
          },
        },
        min:
          percentages &&
          graphDates?.length < 1 &&
          percentages.every((val) => {
            val < 1;
          })
            ? -500
            : percentages > 0
              ? percentages?.[0]
              : null,
        max:
          percentages &&
          graphDates?.length < 1 &&
          percentages.every((val) => {
            val < 1;
          })
            ? 500
            : percentages > 0
              ? percentages?.[percentages?.length - 1]
              : null,
        ticks: {
          callback: function (value) {
            return value >= 0 ? `$${value}` : `-$${Math.abs(value)}`;
          },
        },
      },
    },
  };

  return (
    <div className="">
      <div className="pb-20">
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-grey-600"></span>
          <span className="px-8 text-3xs text-grey-600">
            Holdings with Hedge
          </span>
        </span>
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-secondary"></span>
          <span className="b-10 px-8 text-3xs text-secondary">
            Holdings without Hedge
          </span>
        </span>
      </div>
      <Line data={data} options={options} />
    </div>
  );
}

export default LineChart;
