import React from "react";
import { useHedgingContext } from "@/context/betaContext";
import clsx from "clsx";

import isEmpty from "lodash.isempty";

import { LineTitle, ProgressBar } from "./*";
import TotalInvestmentPerformance from "./total-investment-performance";

const BASE_CLASS = "performance-range";

function PerformanceRange(props) {
  const { exposure, progressBar, totalInvestmentPerformance } = props;

  return (
    <div className={clsx(BASE_CLASS, "pt-32")}>
      <div className="container">
        <div className="grid grid-cols-1 gap-x-24 gap-y-32 lg:grid-cols-2">
          {!isEmpty(exposure) ? (
            <div>
              {exposure?.title ? (
                <LineTitle
                  text={exposure?.title}
                  toolTipToggle={exposure?.toolTipToggle}
                  toolTipDetail={exposure?.toolTipDetail}
                  tabToggleData={exposure?.tabToggleData}
                  tabToggle={exposure?.tabToggle}
                  tabCount={exposure?.tabCount}
                  container={false}
                />
              ) : null}

              <div className="pt-24">
                <div className="pb-16">{exposure.description}</div>
                {!isEmpty(progressBar) && <ProgressBar {...progressBar} />}
              </div>
            </div>
          ) : null}
          {!isEmpty(totalInvestmentPerformance) ? (
            <div>
              {totalInvestmentPerformance?.name ? (
                <LineTitle
                  text={totalInvestmentPerformance?.name}
                  toolTipToggle={totalInvestmentPerformance?.toolTipToggle}
                  toolTipDetail={totalInvestmentPerformance?.toolTipDetail}
                  tooltipLeft={totalInvestmentPerformance?.tooltipLeft}
                  container={false}
                />
              ) : null}

              <div className="pt-24">
                <TotalInvestmentPerformance {...totalInvestmentPerformance} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PerformanceRange;
