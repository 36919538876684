import React from "react";
import clsx from "clsx";

import isEmpty from "lodash.isempty";
import get from "lodash.get";

import { HtmlFragment, Cta } from "./*";
import Image from "next/image";

const BASE_CLASS = "next-block";

function NextBlock(props) {
  const { className = "", description, image, cta } = props;

  return !isEmpty(props) ? (
    <div className={clsx(BASE_CLASS, className)}>
      <div className="grid grid-cols-12 gap-16 pb-0 ipad:pb-56">
        <div className="col-span-12 rounded-lg bg-grey-200 px-16 py-32 md:px-40">
          <div className="col-span-12 grid grid-cols-12 items-center gap-24 ipad:col-span-8">
            {get(image, "url") || description ? (
              <span className="col-span-12 grid grid-cols-[80px_1fr] gap-16 md:gap-24 ipad:col-span-7">
                {get(image, "url") ? (
                  <span>
                    <Image
                      src={image.url}
                      alt={image.altText}
                      layout="responsive"
                      width={100}
                      height={100}
                    />
                  </span>
                ) : null}
                {description ? (
                  <span className="text-body-sm font-mazzardSemiBold text-dark">
                    <HtmlFragment>{description}</HtmlFragment>
                  </span>
                ) : null}
              </span>
            ) : null}
            {get(cta, "link.href") ? (
              <span className="col-span-12 ipad:col-span-5">
                <Cta {...cta} />
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default NextBlock;
