import { useEffect, useRef, useState } from "react";
import { mutate } from "swr";
import { useAuth0 } from "@auth0/auth0-react";
import { useHedgingContext } from "@/context/betaContext";
import clsx from "clsx";

import map from "lodash.map";
import isEmpty from "lodash.isempty";

import { apiKeys } from "@/lib/regex";
import { apiGetRequest } from "@/lib/api";

import { useRouter } from "next/router";

import { Icon } from "./*";

function Dropdown(props) {
  const {
    handleSelectPortfolio,
    handleAddNewRow,
    handleIsHoldings,
    handleTableData,
    tableData,
    isSelectPortfolio,
    getAllPortfolio,
    isNewRow,
    handleEditHolding,
    handleSaveHoldings,
    handleUpdateDropLabel,
    handleModifyHedge,
    handleNewInverseEtf,
    handleSelectMultiple,
    handleSelectIndex,
    handleDisableButton,
    handleIsLoginTableData,
    handleEditDataEnable,
    handleEditTableData,
    getHedgeData,
    handleTabDataChange,
    handleRecall,
    handleChangeNotificationTab,
    handleUpdateModifyHedge,
    handlePortfolioName,
    handleRangeData,
    handleModifyRange,
    handleUpdateDropdown,
    handleHandleWarning,
    handleGetIndexData,
    handleTickerCheck,
    handleNegativeTickers,
    handleIsDropChange,
    handleDefaultUpdateTicker,
    handleCheckDropAbove,
    handleCheckDropBelow,
    handleChangePercent,
    handleSettingTabActive,
  } = useHedgingContext();

  const router = useRouter();

  const { isAuthenticated, user } = useAuth0();

  const { list, className, setValue, event } = props;

  const [openDrop, setOpenDrop] = useState(false);
  const [dropLabel, setDropLabel] = useState("Draft Porfolio");
  const [openDropChange, setOpenDropChange] = useState(false);
  const [visiblePortfolios, setVisiblePortfolios] = useState(false);
  const dropdown = useRef(null);

  const dropDisabled =
    !isAuthenticated || (isAuthenticated && isEmpty(getAllPortfolio));

  const portfolioData = getAllPortfolio?.find(
    (val) => val.ID === Number(router?.query?.portfolio_id)
  );

  const idBool = list?.findIndex((s, i) => s.id === isSelectPortfolio);

  useEffect(() => {
    const idBool = list?.findIndex((s, i) => s.id === isSelectPortfolio);
    const portfolioId = localStorage.getItem("portfolio_Id");

    const idBool2 = list?.find((val) => val.id === Number(portfolioId));

    const timeout = setTimeout(() => {
      setDropLabel(
        !!router?.query?.portfolio_id
          ? portfolioData?.NAME
          : portfolioId
          ? idBool2?.label
          : isNewRow
          ? "Draft Holdings"
          : !isEmpty(getAllPortfolio) && (!isSelectPortfolio || idBool === -1)
          ? list?.[0]?.label
          : !isEmpty(getAllPortfolio) && !!isSelectPortfolio && idBool > -1
          ? list?.[idBool]?.label
          : dropLabel
      );
    }, 1000);
    return () => clearTimeout(timeout);
  }, [
    dropLabel,
    getAllPortfolio,
    isNewRow,
    isSelectPortfolio,
    list,
    portfolioData?.NAME,
    router?.query?.portfolio_id,
  ]);

  useEffect(() => {
    handleSelectPortfolio(
      router?.query?.portfolio_id ? router?.query?.portfolio_id : list?.[0]?.id
    );
  }, [handleSelectPortfolio, list, router?.query?.portfolio_id]);

  useEffect(() => {
    handleUpdateDropLabel(dropLabel);
  }, [dropLabel, handleUpdateDropLabel]);

  const handleOpenDrop = () => {
    setOpenDrop((prev) => !prev);
  };

  const handleNewRow = () => {
    handleAddNewRow(true);
    handleSaveHoldings(true);
    handleNewInverseEtf(false);
    handleSelectMultiple(null);
    handleSelectIndex(null);
    handleRecall(true);
    handleRangeData(50);
    handleHandleWarning(false);
    handleModifyHedge(false);
    handleUpdateDropdown(false);
    handleGetIndexData("");
    handleEditTableData([]);
    handleIsDropChange(false);
    handleTickerCheck(false);
    handleDefaultUpdateTicker(true);
    handleSettingTabActive(false);

    const data = tableData?.filter((val) => val.ticker == "");
    const initialData = [
      {
        ticker: "",
        ticker_name: "",
        market_close_value: "",
        weight: "",
        estUnit: "",
        value: "",
      },
    ];

    handleTableData(data.length ? data : initialData);
    handleIsHoldings(false);
    handleEditHolding(false);
    handleDisableButton(true);
    handleSaveHoldings(false);
    handlePortfolioName("");
    handleNegativeTickers([]);

    router.push(`hedge-builder`);

    // if (getAllPortfolio.length === 20) {
    //   handleIsAlert({
    //     open: true,
    //     message: "Maximum 20 Portfolios are allowed.",
    //     error: false,
    //   });
    // }
  };

  const handleDropLabel = async (str, id) => {
    handleSaveHoldings(false);
    setDropLabel(str);
    handleUpdateDropLabel(str);
    handleSelectPortfolio(id);
    setOpenDropChange(true);
    handleAddNewRow(false);
    handleEditHolding(false);
    handleModifyHedge(false);
    handleDisableButton(false);
    handleIsLoginTableData([]);
    handleEditTableData(getHedgeData.holdings);
    handleEditDataEnable(true);
    handleTabDataChange(true);
    handleRecall(true);
    handleChangeNotificationTab(false);
    handleUpdateModifyHedge(false);
    handleModifyRange(false);
    handleUpdateDropdown(false);
    handleHandleWarning(false);
    handleNegativeTickers([]);
    handleTickerCheck(false);
    handleTableData([]);
    handleCheckDropAbove(false);
    handleCheckDropBelow(false);
    handleChangePercent(false);
    handleSettingTabActive(false);
    localStorage.removeItem("percentage");
    localStorage.removeItem("portfolio_Id");
    // router.push(`hedge-builder?portfolio_id=${id}&tab=myholdings`);
    router.push(`hedge-builder?portfolio_id=${id}`);

    await mutate(apiKeys["hedgePortfolioKey"], () =>
      apiGetRequest(`api/getallportfolio?oktaid=${user?.sub}`)
    );
  };

  useEffect(() => {
    async function fetchHoldingData() {
      await mutate(
        !isNewRow
          ? apiKeys["singleHolding"](user?.sub, isSelectPortfolio)
          : null,
        () =>
          apiGetRequest(
            `api/getholdings?oktaid=${user?.sub}&portfolioid=${isSelectPortfolio}`
          ),
        { revalidate: true }
      );
    }

    if (!!isSelectPortfolio) fetchHoldingData();
  }, [isNewRow, isSelectPortfolio, user?.sub]);

  useEffect(() => {
    if (!openDrop) return;

    function handleClick(event) {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setOpenDrop(false);
      }
    }

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, [openDrop]);

  useEffect(() => {
    if (!!getAllPortfolio) {
      const timer = setTimeout(() => {
        setVisiblePortfolios(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [getAllPortfolio]);

  return (
    <div className="relative" ref={dropdown}>
      {!visiblePortfolios ? (
        <ButtonLoader />
      ) : (
        <button
          onClick={handleOpenDrop}
          className={clsx(
            "relative z-10 flex items-center justify-between overflow-hidden p-12 font-mazzardSemiBold text-xs",
            className,
            { "cursor-not-allowed opacity-50": dropDisabled }
          )}
          disabled={dropDisabled ? true : false}
        >
          <span>{dropDisabled ? "Draft Holdings" : dropLabel}</span>
          <Icon
            name="dropdown-caret"
            className={clsx(
              "origin-center transform justify-self-end transition duration-200 ease-out",
              { "!rotate-180": openDrop }
            )}
          />
        </button>
      )}

      {openDrop ? (
        <ul
          role="listbox"
          onClick={handleOpenDrop}
          className="absolute z-[99] mt-2 max-h-[300px] w-full overflow-y-auto rounded-md bg-white py-2 shadow-xl"
          onChange={event}
        >
          {map(list, ({ label, id }, i) => {
            return (
              <li key={`${label?.toString()?.split(" ").join("-")}-${i}`}>
                <button
                  onClick={() => {
                    handleOpenDrop, handleDropLabel(label, id);
                  }}
                  href="#"
                  className="block w-full cursor-pointer p-12 text-start text-sm hover:font-mazzardSemiBold"
                  aria-label="Close alert"
                >
                  {label}
                </button>
              </li>
            );
          })}
          {isAuthenticated ? (
            <li>
              <button
                className="flex w-full cursor-pointer justify-between p-12 text-start text-sm text-blue-200 underline hover:font-mazzardSemiBold"
                onClick={handleNewRow}
                aria-label="Add portfolio"
              >
                {/* <span>Add New Holdings</span> */}
                <span>Add New Portfolio</span>
                <Icon name="plus" />
              </button>
            </li>
          ) : null}
        </ul>
      ) : null}
    </div>
  );
}

function ButtonLoader() {
  return (
    <div className="w-[220px] rounded-md">
      <div className="flex animate-pulse space-x-4">
        <div className="space-y-6 py-1 flex-1">
          <div className="h-48 rounded bg-slate-200"></div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
