import clsx from "classnames";

import isEmpty from "lodash.isempty";
import get from "lodash.get";
import map from "lodash.map";
import Image from "next/image";

import { HtmlFragment, Cta, RichText, Note } from "./*";

const BASE_CLASS = "benefits";

function Benefits(props) {
  let {
    className = "",
    richText,
    body,
    iconList,
    cta,
    borderTop,
    note,
    anchorId = null,
  } = props;

  return !isEmpty(props) ? (
    <div className={clsx(BASE_CLASS, className)} id={anchorId}>
      <div className="pt-24 ipad:pt-32">
        {richText ? <RichText {...richText} /> : null}
        <div
          className={clsx(
            "container grid grid-flow-row grid-cols-12 grid-rows-1 gap-8",
            {
              "border-t border-grey-600": borderTop,
            }
          )}
        >
          <div
            className={clsx("col-span-12 ipad:col-span-8", {
              "pb-64 md:pb-80": get(cta, "link.href"),
            })}
          >
            {!isEmpty(iconList)
              ? map(
                  Array.isArray(iconList) ? iconList : [iconList],
                  ({ image, header, content }, i) => {
                    return get(image, "url") || header || content ? (
                      <div
                        key={i}
                        className={clsx("flex gap-16 py-12 lg:gap-24")}
                      >
                        {get(image, "url") ? (
                          <div className="icon basis-[20%] p-8 md:basis-[7%]">
                            <Image
                              src={image.url}
                              alt={image.altText}
                              layout="responsive"
                              width={100}
                              height={100}
                            />
                          </div>
                        ) : null}
                        {header || content ? (
                          <div className="content col-span-5 basis-[93%] text-dark">
                            {header ? (
                              <div className="h4 mb-0 font-mazzardSemiBold text-dark">
                                {header}
                              </div>
                            ) : null}
                            {content ? (
                              <div className="text-body-lg">
                                <HtmlFragment>{content}</HtmlFragment>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    ) : null;
                  }
                )
              : null}
            {body ? (
              <div className="text-body-lg list-inside pt-8 text-dark ipad:pt-12">
                <HtmlFragment>{body}</HtmlFragment>
              </div>
            ) : null}
            {get(cta, "link.href") ? <Cta {...cta} /> : null}
          </div>
          {!isEmpty(note) ? (
            <div className="col-span-12 ipad:col-span-4">
              <Note note={note} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}

export default Benefits;
