import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import {
  getEquidistantDates,
  formatDate,
  getEquidistantPercentage,
} from "@/lib/text";
import isEmpty from "lodash.isempty";
import Accordion from "./accordion";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TestLineChart(props) {
  const { apiData, accordionList } = props;
  // const { graphData } = useHedgingContext();

  // api data check
  const [lineJson, setLineJson] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [jsonData, setJsonData] = useState(null);

  // Verify the result

  const handleMessageChange = (event) => {
    // const dataArray = JSON.parse(event.target.value);
    setLineJson(event.target.value);
  };

  useEffect(() => {
    setGraphData(lineJson);
  }, [lineJson]);

  // all dates
  const graphDates = useMemo(
    () =>
      !isEmpty(graphData) && Array.isArray(graphData)
        ? graphData
            ?.sort(
              (a, b) =>
                new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
            )
            ?.reduce((acc, item) => {
              acc = [...acc, formatDate(item.MARKET_CLOSE_DATE)];

              return acc;
            }, [])
        : null,
    [graphData]
  );

  // equidistant dates
  const newGraphDates = useMemo(
    () =>
      !isEmpty(graphDates)
        ? getEquidistantDates(graphDates?.slice(0), 5)
        : null,
    [graphDates]
  );
  // all hedged percentages
  const hedgedValues =
    !isEmpty(graphData) && Array.isArray(graphData)
      ? graphData
          ?.filter((f) => formatDate(f.MARKET_CLOSE_DATE))
          ?.sort(
            (a, b) =>
              new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
          )
          ?.reduce((acc, item, i) => {
            acc = [...acc, i === 0 ? 0 : item.HEDGE_TOTAL_DIFFRENCE];

            return acc;
          }, [])
      : null;

  // all unhedged percentages
  const unHedgedValues = useMemo(
    () =>
      !isEmpty(graphData) && Array.isArray(graphData)
        ? graphData
            ?.filter((f) => {
              return formatDate(f.MARKET_CLOSE_DATE);
            })
            ?.sort(
              (a, b) =>
                new Date(a.MARKET_CLOSE_DATE) - new Date(b.MARKET_CLOSE_DATE)
            )
            ?.reduce((acc, item, i) => {
              acc = [...acc, i === 0 ? 0 : item.UNHEDGED_TOTAL_DIFFRENCE];

              return acc;
            }, [])
        : null,
    [graphData]
  );

  //equidistant percentages
  const percentages = useMemo(() => {
    return !isEmpty(hedgedValues) && !isEmpty(unHedgedValues)
      ? getEquidistantPercentage(
          [...hedgedValues, ...unHedgedValues]?.sort((a, b) => b - a)
        )
      : null;
  }, [hedgedValues, unHedgedValues]);

  const data = {
    labels: graphDates,
    datasets: [
      {
        label: "Holdings with Hedge",
        data: hedgedValues,
        borderColor: "#415580",
        backgroundColor: "#415580",
        fill: false,
        tension: 1,
        pointRadius: 1,
      },
      {
        label: "Holdings without Hedge",
        data: unHedgedValues,
        borderColor: "#771B85",
        backgroundColor: "#771B85",
        fill: false,
        tension: 0,
        pointRadius: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    lineTension: 0.1,
    plugins: {
      tooltip: {
        intersect: false,
        mode: "index",
        callbacks: {
          title: function (tooltipItems, data) {
            return data?.labels?.[tooltipItems[0].index];
          },
          label: function (context) {
            let label = "";

            if (context?.parsed?.y !== null) {
              if (context?.parsed?.y >= 0) {
                label += ` $${Number(context?.parsed?.y)?.toFixed(2)}`;
              } else {
                label += ` -$${Math.abs(
                  Number(context?.parsed?.y)?.toFixed(2)
                )}`;
              }
            }

            return label;
          },
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: "top",
        align: "start",
        labels: {
          padding: 20,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 30,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // true : if you need x axis line
        },
        border: {
          display: false,
        },
        ticks: {
          font: { size: 10 },
          // maxTicksLimit: 6,
          callback: function (value, index) {
            const text = newGraphDates?.includes(this.getLabelForValue(value))
              ? `${this.getLabelForValue(value)}`
              : "";

            return text;
          },
          maxRotation: 0,
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: false,
        border: {
          display: false,
        },
        grid: {
          color: (context) => {
            if (context.tick?.value === 0) {
              return "rgba(0,0,0,1)";
            } else {
              return "rgba(116,120,141,0.3)";
            }
          },
        },
        min:
          percentages && percentages.every((val) => val < 1)
            ? -500
            : percentages?.[0],
        max:
          percentages && percentages.every((val) => val < 1)
            ? 500
            : percentages?.[percentages?.length - 1],
        ticks: {
          stepSize:
            percentages && percentages.every((val) => val < 1)
              ? 100
              : Math.abs(percentages?.[0] - percentages?.[1]),
          callback: function (value, index) {
            if (percentages && percentages.every((val) => val < 1)) {
              return `$${value}`;
            }

            let magnitude = Math.abs(value);
            let roundedValue;
            if (magnitude >= 10000) {
              roundedValue = Math.round(value / 10000) * 10000;
            } else if (magnitude >= 1000) {
              roundedValue = Math.round(value / 1000) * 1000;
            } else if (magnitude >= 100) {
              roundedValue = Math.round(value / 100) * 100;
            } else {
              roundedValue = Math.round(value / 10) * 10;
            }

            return `$${roundedValue}`;
          },
        },
      },
    },
  };

  // check uploader

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.type === "application/json") {
        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            // Debugging: Check file content before parsing

            // Removing any potential BOM characters
            let fileContent = e.target.result;
            if (fileContent.charCodeAt(0) === 0xfeff) {
              fileContent = fileContent.substring(1);
            }

            // Parse JSON
            const data = JSON.parse(fileContent);

            // Update state
            setLineJson(data);
            setJsonData(data); // Store data for displaying if needed
          } catch (error) {
            console.error("Error parsing JSON:", error);
            alert("Error parsing JSON file. Please ensure it is valid JSON.");
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          alert("Error reading file. Please try again.");
        };

        reader.readAsText(file);
      } else {
        alert("Please upload a valid JSON file.");
      }
    } else {
      alert("No file selected. Please choose a file.");
    }
  };

  const body = {
    MARKET_CLOSE_DATE: "6/10/2024",
    UNHEDGED_TOTAL: 373876.424,
    HEDGED_TOTAL: 472492.6839,
    CREATED_DATE: "6/12/2024 12:00:00 PM",
  };

  return (
    <div className="container">
      <div className="py-20 font-mazzardSemiBold text-lg text-dark">
        Line Chart :
      </div>
      <div className="pb-20">
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-grey-600"></span>
          <span className="px-8 text-3xs text-grey-600">
            Holdings with Hedge
          </span>
        </span>
        <span>
          <span className="inline-block h-[12px] w-[12px] bg-secondary"></span>
          <span className="b-10 px-8 text-3xs text-secondary">
            Holdings without Hedge
          </span>
        </span>
      </div>
      <Line data={data} options={options} />

      {apiData ? (
        <>
          <div className="py-10">
            <input type="file" accept=".json" onChange={handleFileChange} />
          </div>
          <div className="my-20 bg-gray-100 p-12">
            <pre>{JSON.stringify(body, null, 2)}</pre>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default TestLineChart;
